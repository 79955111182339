import React, { useEffect, useState, useMemo } from 'react';
import styles from './BookingDetails.module.css';
import registerStyles from './Register.module.css';
import forgotStyles from './ForgotPassword.module.css';
import loginStyles from './Login.module.css';
import axios from 'axios';
import { FaCalendarDay, FaUsers } from "react-icons/fa";
import { MdGolfCourse } from "react-icons/md";
import { HiUsers } from "react-icons/hi";
import { RiCircleFill } from "react-icons/ri";
import { MdKeyboardArrowDown } from "react-icons/md";
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { MdArrowDropDown } from "react-icons/md";
import { FaClock } from "react-icons/fa6";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import { RiStickyNoteAddFill } from "react-icons/ri";
import { FaUser } from "react-icons/fa";
import { API_KEY, API_URL, timeData, timeSheetData } from '../constant';
import { useNavigate, useParams } from 'react-router-dom';
import ReCAPTCHA from 'react-google-recaptcha';
import SlotSheet from '../Components/SlotSheet';
import { IoMdArrowDropdown, IoMdArrowDropup } from "react-icons/io";
import Loader from '../Components/Loader';
import Swal from 'sweetalert2';
import countryList from 'react-select-country-list';
import CustomDropdown from '../Components/CustomDropdown';
import Test from '../Components/Test';
import Login from './Login';
import { AiOutlineClose } from "react-icons/ai";
import refreshAccessToken from '../refreshAccessToken';
import { useSelector, useDispatch } from 'react-redux';
import { setAccessToken, setRefreshToken } from '../features/auth/authSlice';

const BookPromotion = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { id } = useParams();

    const accessToken = useSelector((state) => state.auth.accessToken);

    const [loading, setLoading] = useState(false);
    const [course, setCourse] = useState(null);
    const [promotion, setPromotion] = useState(null);
    const [showDates, setShowDates] = useState(true);
    const [showHoles, setShowHoles] = useState(false);
    const [showPlayers, setShowPlayers] = useState(false);
    const [showCalendar, setShowCalendar] = useState(false);
    const [showTime, setShowTime] = useState(false);
    const [showExtras, setShowExtras] = useState(false);
    const [showUser, setShowUser] = useState(false);
    const [time, setTime] = useState(null);
    const [price, setPrice] = useState(null);
    const [allHoles, setAllHoles] = useState([]);
    const [selectedHole, setSelectedHole] = useState(null);
    const [hole, setHole] = useState(null);
    const [selectedPlayers, setSelectedPlayers] = useState([]);
    const [players, setPlayers] = useState(0);
    const [playerDetails, setPlayerDetails] = useState([]);
    const [user, setUser] = useState(null);
    const [selectedDate, setSelectedDate] = useState(null);
    const [openDropdowns, setOpenDropdowns] = useState([]);
    const [showCaptcha, setShowCaptcha] = useState(false);
    const [offDates, setOffDates] = useState([]);
    const [offDays, setOffDays] = useState([]);
    const [timeSheetData, setTimeSheetData] = useState([]);
    const [filteredTimeSheetData, setFilteredTimeSheetData] = useState([]);
    const [selectedTeeTime, setSelectedTeeTime] = useState(null);
    const [showTeeTimeSlab, setShowTeeTimeSlab] = useState(false);
    const [expandedSlab, setExpandedSlab] = useState(null);
    const [selectedValue, setSelectedValue] = useState('');
    const [caddy, setCaddy] = useState(false);
    const [cart, setCart] = useState(false);
    const [buggy, setBuggy] = useState(false);
    const [blockToggleShowFromExtras, setBlockToggleShowFromExtras] = useState(false);
    const [blockToggleShowFromPlayers, setBlockToggleShowPlayers] = useState(false);

    const options = useMemo(() => countryList().getData(), []);

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('')
    const [otp, setOtp] = useState('');
    const [pin, setPin] = useState('');
    const [confirmPin, setConfirmPin] = useState('');
    const [userDetails, setUserDetails] = useState(null);

    const [showBooking, setShowBooking] = useState(true);
    const [showLogin, setShowLogin] = useState(false);
    const [showRegister, setShowRegister] = useState(false);
    const [showForgotPassword, setShowForgotPassword] = useState(false);
    const [showPin, setShowPin] = useState(false);

    const getFirstCourse = async () => {
        try {
            setLoading(true);

            const response = await axios.get(API_URL + "/api/getFirstCourse");

            if (response.status === 200) {
                if (response.data.length > 0) {
                    const courseId = response.data[0].CourseID;
                    setCourse(response.data[0]);

                    fethOffDates(courseId);
                    fetchOffDays(courseId);
                }
            }
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    }

    const promotionDetails = async (id) => {
        try {
            setLoading(true);

            const response = await axios.get(API_URL + "/api/getPromotionById/" + id);
            console.log(response.data);

            fethOffDates();
            fetchOffDays();

            setPromotion(response.data);
            setHole(response.data.HoleName);
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    }

    const getPromotionDataById = async (id, date) => {
        try {
            console.log(formatDateForApi(new Date()));
            setLoading(true);

            const response = await axios.post(API_URL + "/api/getPromotionTimeSheetByPromotionID", {
                "CourseID": 1,
                "PromotionID": id,
                "Date": formatDateForApi(date)
            });

            fethOffDates();
            fetchOffDays();

            setPromotion(response.data);
            setHole(response.data.HoleName);
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    }

    const generateDates = (offDates, offDays, targetCount = 7, endDate = promotion?.EndDate) => {
        const dates = [];
        const today = new Date();
        const end = endDate ? new Date(endDate) : null;
        let dayOffset = 0; // Tracks the current day offset

        // Function to zero out time for accurate date-only comparison
        const normalizeDate = (date) => new Date(date.getFullYear(), date.getMonth(), date.getDate());

        const normalizedEnd = end ? normalizeDate(end) : null;

        // Get day names
        const dayNames = ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"];

        while (dates.length < targetCount) {
            const date = new Date(today);
            date.setDate(today.getDate() + dayOffset);

            // Exclude today if the time is past 14:00 (2 PM)
            if (dayOffset === 0 && today.getHours() >= 14) {
                dayOffset++;
                continue; // Skip today and move to the next day
            }

            // Stop if we go past the end date
            if (normalizedEnd && normalizeDate(date) > normalizedEnd) {
                break;
            }

            // Format the date as "YYYY-MM-DD"
            const formattedDate = date.toISOString().split("T")[0];

            // Check if the date is valid
            const dayName = dayNames[date.getDay()];
            if (!offDates.includes(formattedDate) && !offDays.includes(dayName)) {
                dates.push(date); // Add date without modifications
            }

            dayOffset++; // Move to the next day
        }

        return dates;
    };

    const formatDate = (date) => {
        const options = { weekday: 'long', day: 'numeric', month: 'long' };
        return date.toLocaleDateString('en-US', options);
    };

    const availableDates = generateDates(offDates, offDays);

    const fethOffDates = async (courseId) => {
        const response = await axios.get(API_URL + "/api/getAllDateOffs/" + courseId);
        setOffDates(["2024-12-09", "2025-01-01"]); // Example: ["2024-12-09","2024-12-11"]
    }

    const fetchOffDays = async (courseId) => {
        const response = await axios.get(API_URL + "/api/getAllDayOffs/" + courseId);
        setOffDays(["SUN", "SAT"]); // Example: ["SUN","SAT"]
    }

    useEffect(() => {
        // getFirstCourse();
        promotionDetails(id);
    }, []);

    const isDisabledDate = (date) => {
        const currentDate = new Date();
        const today = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate());
        const currentTime = currentDate.getHours() * 60 + currentDate.getMinutes();
        const twoPM = 14 * 60;

        const localDate = new Date(date.getFullYear(), date.getMonth(), date.getDate());
        const formattedDate = [
            localDate.getFullYear(),
            String(localDate.getMonth() + 1).padStart(2, '0'),
            String(localDate.getDate()).padStart(2, '0')
        ].join("-");

        if (localDate < today) {
            return true;
        }

        if (localDate.getTime() === today.getTime() && currentTime >= twoPM) {
            return true;
        }

        if (offDates.includes(formattedDate)) {
            return true;
        }

        const dayNames = ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"];
        const dayName = dayNames[date.getDay()];
        if (offDays.includes(dayName)) {
            return true;
        }

        if (promotion?.EndDate < formattedDate) {
            return true;
        }

        return false;
    };

    const filterTimeSheetData = (timeSheetData, selectedHole, selectedDate) => {
        const currentDate = new Date(); // Get the current date and time
        const formattedDate = currentDate.toISOString().split("T")[0]; // Format as YYYY-MM-DD
        const isToday = formattedDate === selectedDate; // Check if selectedDate is today

        const filteredData = timeSheetData
            .filter((item) => {
                const match = item.HoleName === selectedHole.HoleName;
                return match; // Match the selected hole
            })
            .map((item) => {
                return {
                    ...item,
                    Slabs: item.Slabs.filter((slab) => {
                        if (isToday) {
                            // Constructing slabStartTime correctly
                            const [hour, minute] = slab.StartingTime.split(":").map(Number);
                            const slabStartTime = new Date(currentDate);
                            slabStartTime.setHours(hour, minute, 0, 0); // Set time based on slab

                            const differenceInMinutes = (slabStartTime - currentDate) / (60 * 1000); // Difference in minutes
                            return differenceInMinutes >= 30; // Include slabs 30+ minutes away
                        }
                        return true; // Include all slabs if not today
                    }).map((slab) => {
                        return {
                            ...slab,
                            TeeTimes: slab.TeeTimes.filter((teeTime) => {
                                if (isToday) {
                                    const [hour, minute] = teeTime.StartTeeTime.split(":").map(Number);
                                    const teeStartTime = new Date(currentDate);
                                    teeStartTime.setHours(hour, minute, 0, 0); // Set time based on tee time

                                    const differenceInMinutes = (teeStartTime - currentDate) / (60 * 1000); // Difference in minutes
                                    return differenceInMinutes >= 30; // Include tee times 30+ minutes away
                                }
                                return true; // Include all tee times if not today
                            }),
                        };
                    }),
                };
            })
            .filter((item) => item.Slabs.length > 0); // Remove items with no valid slabs
        setExpandedSlab(filteredData[0]?.Slabs[0]?.SlabID);
        return filteredData;
    };

    const handleShowJoin = (players, filledSlot, totalSlot) => {
        const remainingSlots = totalSlot - filledSlot;

        const isOpenForJoin = filledSlot < 3;

        const canPlayersJoin = players <= remainingSlots;

        if (isOpenForJoin && canPlayersJoin) {
            return true;
        }
        return false;
    };

    const handleDateSelect = (date) => {
        setPlayers(0);
        setSelectedTeeTime(null);
        setSelectedDate(date);
        getPromotionDataById(id, date);
        handleShowDates();
        setShowPlayers(true);
    };

    const handleHoleSelect = () => {
        setShowHoles(!showHoles);
        setHole(selectedHole);
        setFilteredTimeSheetData(filterTimeSheetData(timeSheetData, selectedHole, formatDateForApi(selectedDate)));
        setShowPlayers(true);
        setPlayers(0);
        setSelectedTeeTime(null);
    };

    const handleSelectPlayers = () => {
        setBlockToggleShowPlayers(false);
        setShowPlayers(!showPlayers);
        setPlayers(selectedPlayers.length);
        handleShowPlayers();
        setShowExtras(true);
        setSelectedTeeTime(null);
    }

    const handleExtrasSelect = () => {
        setBlockToggleShowFromExtras(false);
        setShowExtras(!showExtras);
        setShowTime(true);
    }

    const handleTimeClick = (tee, price, noOfPlayers) => {
        setSelectedTeeTime(tee);
        setShowTime(false);
        setShowUser(true);
        setPrice(calulatePrice(price, noOfPlayers));
    };

    const handleShowDates = () => {
        setBlockToggleShowFromExtras(false);
        setBlockToggleShowPlayers(false);

        if (showDates === true) {
            setShowDates(false);
        } else {
            setShowDates(true);
            setShowHoles(false);
            setShowPlayers(false);
            setShowExtras(false);
            setShowTime(false);
        }
    }

    const handleShowHoles = () => {
        Swal.fire({
            icon: "error",
            title: "Error",
            text: "Cannot select a hole in promotion",
        });
        return;
    }

    const handleShowPlayers = () => {
        setBlockToggleShowFromExtras(false);
        setBuggy(0);
        setCart(0);
        setCart(0);

        if (blockToggleShowFromPlayers) {
            return;
        }

        if (selectedDate === null) {
            Swal.fire({
                icon: "error",
                title: "Error",
                text: "Please select a date",
            });
            setShowDates(true);
            setShowHoles(false);
            setShowPlayers(false);
            setShowExtras(false);
            setShowTime(false);
            return;
        }

        if (hole === null) {
            Swal.fire({
                icon: "error",
                title: "Error",
                text: "Please select a hole",
            });
            setShowDates(true);
            setShowHoles(false);
            setShowPlayers(false);
            setShowExtras(false);
            setShowTime(false);
            return;
        }

        if (showPlayers === true) {
            setShowPlayers(false);
        } else {
            setShowDates(false);
            setShowPlayers(true);
            setShowHoles(false);
            setShowExtras(false);
            setShowTime(false);
        }
    }

    const handleShowExtras = () => {
        if (blockToggleShowFromExtras) {
            return;
        }

        if (blockToggleShowFromPlayers) {
            return;
        }

        if (showExtras === true) {
            setShowExtras(false);
        } else {
            setShowDates(false);
            setShowHoles(false);
            setShowPlayers(false);
            setShowExtras(true);
            setShowTime(false);
        }
    }

    const handleShowTime = () => {
        if (blockToggleShowFromExtras) {
            return;
        }

        if (blockToggleShowFromPlayers) {
            return;
        }

        if (selectedDate === null) {
            Swal.fire({
                icon: "error",
                title: "Error",
                text: "Please select a date",
            });
            setShowDates(true);
            setShowHoles(false);
            setShowPlayers(false);
            setShowExtras(false);
            setShowTime(false);
            return;
        }

        if (hole === null) {
            Swal.fire({
                icon: "error",
                title: "Error",
                text: "Please select a hole",
            });
            setShowDates(false);
            setShowHoles(true);
            setShowPlayers(false);
            setShowExtras(false);
            setShowTime(false);
            return;
        }

        if (players === 0) {
            Swal.fire({
                icon: "error",
                title: "Error",
                text: "Please select players",
            });
            setShowDates(false);
            setShowHoles(false);
            setShowPlayers(true);
            setShowExtras(false);
            setShowTime(false);
            return;
        }

        if (showTime === true) {
            setShowTime(false);
        } else {
            setShowDates(false);
            setShowTime(true);
            setShowHoles(false);
            setShowExtras(false);
            setShowPlayers(false);
        }
    }

    const formatDateForApi = (dateString) => {
        if (dateString !== null) {
            const date = new Date(dateString);
            const day = String(date.getDate()).padStart(2, '0');
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const year = date.getFullYear();
            return `${year}-${month}-${day}`;
        } else {
            return null;
        }
    }

    const createBody = () => {

        if (selectedTeeTime === null) {
            setShowTime(true);
            return {
                error: 'Please select a time'
            }
        }

        if (hole === null) {
            setShowHoles(true);
            return {
                error: 'Please select a hole type'
            }
        }

        if (players === null) {
            setShowPlayers(true);
            return {
                error: 'Please select number of players'
            }
        }

        if (selectedDate === null) {
            setShowDates(true);
            return {
                error: 'Please select a date'
            }
        }

        return {
            "TeeTimeID": selectedTeeTime.TeeTimeID,
            "Date": formatDateForApi(selectedDate),
            "TotalGolfers": players,
            "HoleMappingID": hole,
            "BookingPricing": price,
            "Cart": cart ? 1 : 0,
            "Buggy": buggy ? 1 : 0,
            "Caddy": caddy ? 1 : 0,
            "PromotionID": promotion.PromotionID
        }
    }

    const handlePlayerCategoryChange = (index, value) => {
        setSelectedPlayers((prevPlayers) =>
            prevPlayers.map((player, i) =>
                i === index ? { ...player, category: value } : player
            )
        );
    };

    useEffect(() => {
        setSelectedPlayers((prevPlayers) => {
            const updatedPlayers = Array.from({ length: players }, (_, index) => ({
                category: prevPlayers[index]?.category || 'Public', // Retain existing data or default
            }));
            return updatedPlayers;
        });
    }, [players]);

    const calulatePrice = (price, noOfPlayers) => {
        return price * noOfPlayers;
    }

    const handleBookTeeTime = async () => {
        setShowDates(false);
        setShowHoles(false);
        setShowPlayers(false);
        setShowExtras(false);
        setShowTime(false);

        const body = createBody();
        console.log(body);
        if (body.error) {
            Swal.fire({
                icon: "error",
                title: "Error",
                text: body.error,
            });
            return;
        }

        try {
            setLoading(true);

            const response = await axios.post(`${API_URL}/api/createNewBooking`, body, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`
                },
            });

            if (response.status === 201) {
                Swal.fire({
                    icon: "success",
                    title: "Success",
                    text: response.data.message,
                    showConfirmButton: true,
                    confirmButtonText: "OK",
                }).then((result) => {
                    if (result.isConfirmed) {
                        navigate("/bookings");
                    }
                });
            }
        } catch (error) {
            console.log(error);

            if (error.response?.status === 500) {
                Swal.fire({
                    icon: "error",
                    title: "Error",
                    text: error.response.data.message,
                });
            }

            if (error.response?.status === 403) {
                await refreshAccessToken();
            }

            if (error.response?.status === 401) {
                await refreshAccessToken();
            }
        } finally {
            setLoading(false);
        }
    }

    const handleSelectLoginAndContinue = () => {
        setShowDates(false);
        setShowHoles(false);
        setShowPlayers(false);
        setShowExtras(false);
        setShowTime(false);

        const body = createBody();
        if (body.error) {
            Swal.fire({
                icon: "error",
                title: "Error",
                text: body.error,
            });
            return;
        }

        setShowBooking(false);
        setShowLogin(true);
        setShowRegister(false);
    }

    const handleLogin = async () => {
        if (email.trim() === '') {
            Swal.fire({
                icon: "error",
                title: "Error",
                text: "Please enter email",
            })
            return;
        }

        if (pin.trim() === '') {
            Swal.fire({
                icon: "error",
                title: "Error",
                text: "Please enter PIN",
            })
            return;
        }

        if (pin.length !== 4) {
            Swal.fire({
                icon: "error",
                title: "Error",
                text: "Please enter a valid PIN",
            })
            return;
        }

        if (!/^\d{4}$/.test(pin)) {
            Swal.fire({
                icon: "error",
                title: "Error",
                text: "Please enter a valid PIN",
            })
            return;
        }

        if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,10})+$/.test(email)) {
            Swal.fire({
                icon: "error",
                title: "Error",
                text: "Please enter a valid email",
            })
            return;
        }

        try {
            setLoading(true);

            console.log({
                "Email": email,
                "Pin": pin,
                "TeeTimeID": selectedTeeTime.TeeTimeID,
                "Date": formatDateForApi(selectedDate),
                "TotalGolfers": players,
                "HoleMappingID": hole,
                "BookingPricing": price,
                "Cart": cart ? 1 : 0,
                "Buggy": buggy ? 1 : 0,
                "Caddy": caddy ? 1 : 0,
                "PromotionID": promotion.PromotionID
            })

            const response = await axios.post(`${API_URL}/api/loginAndBooking`, {
                "Email": email,
                "Pin": pin,
                "TeeTimeID": selectedTeeTime.TeeTimeID,
                "Date": formatDateForApi(selectedDate),
                "TotalGolfers": players,
                "HoleMappingID": hole,
                "BookingPricing": price,
                "Cart": cart ? 1 : 0,
                "Buggy": buggy ? 1 : 0,
                "Caddy": caddy ? 1 : 0,
                "PromotionID": promotion.PromotionID
            });

            if (response.status === 200) {
                dispatch(setAccessToken(response.data.accessToken));
                dispatch(setRefreshToken(response.data.refreshToken));

                navigate('/bookings');
            }
        } catch (error) {
            console.log(error);

            if (error.response?.status === 400) {
                Swal.fire({
                    icon: "error",
                    title: "Error",
                    text: error.response.data.message,
                });
            }

            if (error.response?.status === 401) {
                Swal.fire({
                    icon: "error",
                    title: "Error",
                    text: error.response.data.message,
                });
            }

            if (error.response?.status === 500) {
                Swal.fire({
                    icon: "error",
                    title: "Error",
                    text: error.response.data.message,
                });
            }
        } finally {
            setLoading(false);
        }
    }

    const handleRegister = async () => {
        if (name.trim() === '') {
            Swal.fire({
                icon: "error",
                title: "Error",
                text: "Please enter your name",
            });
            return;
        }

        if (email.trim() === '') {
            Swal.fire({
                icon: "error",
                title: "Error",
                text: "Please enter your email",
            });
            return;
        }

        if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,10})+$/.test(email)) {
            Swal.fire({
                icon: "error",
                title: "Error",
                text: "Please enter a valid email",
            });
            return;
        }

        if (pin.trim() === '') {
            Swal.fire({
                icon: "error",
                title: "Error",
                text: "Please enter your PIN",
            });
            return;
        }

        if (pin.length !== 4) {
            Swal.fire({
                icon: "error",
                title: "Error",
                text: "Please enter a valid PIN",
            });
            return;
        }

        if (!/^\d{4}$/.test(pin)) {
            Swal.fire({
                icon: "error",
                title: "Error",
                text: "Please enter a valid PIN",
            });
            return;
        }

        if (phoneNumber.trim() === '') {
            Swal.fire({
                icon: "error",
                title: "Error",
                text: "Please enter your phone number",
            });
            return;
        }

        if (!/^\d{10}$/.test(phoneNumber)) {
            Swal.fire({
                icon: "error",
                title: "Error",
                text: "Please enter a valid phone number",
            });
            return;
        }

        if (phoneNumber.length !== 10) {
            Swal.fire({
                icon: "error",
                title: "Error",
                text: "Please enter a valid phone number",
            });
            return;
        }

        if (selectedValue.trim() === '') {
            Swal.fire({
                icon: "error",
                title: "Error",
                text: "Please select your gender",
            });
            return;
        }

        try {
            setLoading(true);

            console.log({
                "Name": name,
                "Email": email,
                "Pin": pin,
                "PhoneNumber": phoneNumber,
                "Country": selectedValue,
                "TeeTimeID": selectedTeeTime.TeeTimeID,
                "Date": formatDateForApi(selectedDate),
                "TotalGolfers": players,
                "HoleMappingID": hole,
                "BookingPricing": price,
                "Cart": cart ? 1 : 0,
                "Buggy": buggy ? 1 : 0,
                "Caddy": caddy ? 1 : 0,
            });

            const response = await axios.post(`${API_URL}/api/registerAndBooking`, {
                "Name": name,
                "Email": email,
                "Pin": pin,
                "PhoneNumber": phoneNumber,
                "Country": selectedValue,
                "TeeTimeID": selectedTeeTime.TeeTimeID,
                "Date": formatDateForApi(selectedDate),
                "TotalGolfers": players,
                "HoleMappingID": hole,
                "BookingPricing": price,
                "Cart": cart ? 1 : 0,
                "Buggy": buggy ? 1 : 0,
                "Caddy": caddy ? 1 : 0,
            });

            if (response.status === 200) {
                Swal.fire({
                    icon: "success",
                    title: "Success",
                    text: "Please check your email to verify your account and complete your booking.",
                    showConfirmButton: true,
                    confirmButtonText: "OK",
                }).then((result) => {
                    if (result.isConfirmed) {
                        window.location.reload();
                    }
                })
            }
        } catch (error) {
            console.log(error);

            if (error.response?.status === 400) {
                Swal.fire({
                    icon: "error",
                    title: "Error",
                    text: error.response.data.message,
                });
            }

            if (error.response?.status === 500) {
                Swal.fire({
                    icon: "error",
                    title: "Error",
                    text: error.response.data.message,
                });
            }
        } finally {
            setLoading(false);
        }
    }

    const goToOtpVerify = async () => {
        if (email.trim() === '') {
            Swal.fire({
                icon: "error",
                title: "Error",
                text: "Please enter your email",
            });
            return;
        }

        if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,10})+$/.test(email)) {
            Swal.fire({
                icon: "error",
                title: "Error",
                text: "Please enter a valid email",
            });
            return;
        }

        try {
            setLoading(true);

            const response = await axios.post(`${API_URL}/api/forgot-password`, { email });

            if (response.status === 200) {
                Swal.fire({
                    icon: "success",
                    title: "Success",
                    text: response.data.message,
                });

                setShowForgotPassword(false);
                setShowPin(true);
            }

        } catch (error) {
            console.log(error);
            Swal.fire({
                icon: "error",
                title: "Error",
                text: error.response.data.message,
            });
        } finally {
            setLoading(false);
        }
    }

    const forgotPassword = async () => {
        if (pin.trim() === '') {
            Swal.fire({
                icon: "error",
                title: "Error",
                text: "Please enter your PIN",
            });
            return;
        }

        if (confirmPin.trim() === '') {
            Swal.fire({
                icon: "error",
                title: "Error",
                text: "Please confirm your PIN",
            });
            return;
        }

        if (pin !== confirmPin) {
            Swal.fire({
                icon: "error",
                title: "Error",
                text: "PINs do not match",
            });
            return;
        }

        if (pin.length !== 4) {
            Swal.fire({
                icon: "error",
                title: "Error",
                text: "Please enter a valid PIN",
            });
            return;
        }

        if (!/^\d{4}$/.test(pin)) {
            Swal.fire({
                icon: "error",
                title: "Error",
                text: "Please enter a valid PIN",
            });
            return;
        }

        if (!/^\d{4}$/.test(confirmPin)) {
            Swal.fire({
                icon: "error",
                title: "Error",
                text: "Please enter a valid PIN",
            });
            return;
        }

        if (otp.trim() === '') {
            Swal.fire({
                icon: "error",
                title: "Error",
                text: "Please enter your OTP",
            });
            return;
        }

        if (otp.length !== 6) {
            Swal.fire({
                icon: "error",
                title: "Error",
                text: "Please enter a valid OTP",
            });
            return;
        }

        if (!/^\d{6}$/.test(otp)) {
            Swal.fire({
                icon: "error",
                title: "Error",
                text: "Please enter a valid OTP",
            });
            return;
        }

        try {
            setLoading(true);

            const response = await axios.post(`${API_URL}/api/reset-password`, { email: email, newPassword: pin, otp: otp });

            if (response.status === 200) {
                Swal.fire({
                    icon: "success",
                    title: "Success",
                    text: "Password reset successfully",
                });

                setShowForgotPassword(true);
                setShowPin(false);
                setEmail('');
                setPin('');
                setConfirmPin('');
                setOtp('');
            }

            if (response.status !== 200) {
                Swal.fire({
                    icon: "error",
                    title: "Error",
                    text: response.data.message,
                });
            }
        } catch (error) {
            console.log(error);
            Swal.fire({
                icon: "error",
                title: "Error",
                text: error.response.data.message,
            });
        } finally {
            setLoading(false);
        }
    }

    const handleCloseLogin = () => {
        setShowLogin(false);
        setShowRegister(false);
        setShowBooking(true);
        setShowForgotPassword(false);
    }

    const goToRegister = () => {
        setShowLogin(false);
        setShowRegister(true);
        setShowBooking(false);
        setShowForgotPassword(false);
        setEmail('');
        setPin('');
    }

    const goToLogin = () => {
        setShowLogin(true);
        setShowRegister(false);
        setShowBooking(false);
        setShowForgotPassword(false);
        setEmail('');
        setPin('');
        setName('');
        setPhoneNumber('');
        setSelectedValue('');
    }

    const goToForgotPassword = () => {
        setShowLogin(false);
        setShowRegister(false);
        setShowBooking(false);
        setShowForgotPassword(true);
        setEmail('');
        setPin('');
        setName('');
        setPhoneNumber('');
        setSelectedValue('');
    }

    return (
        <>
            {loading ? (
                <Loader />
            ) : (
                <>
                    {showBooking && (
                        <div className={styles.booking_details_container}>
                            <div className={styles.booking_details_heading}>
                                PROMOTION BOOKING
                            </div>


                            <div className={styles.booking_details_full_container}>
                                <div className={styles.date_selection_div}>
                                    <div className={styles.booking_details_section_upper} onClick={handleShowDates}>
                                        <div className={styles.booking_details_section1}>
                                            <div className={styles.booking_details_section1_left}>
                                                <div className={styles.booking_details_section1_left1}>
                                                    <FaCalendarDay />
                                                </div>
                                                {showDates ? (
                                                    <div className={styles.booking_details_section1_left2}>
                                                        Select Date
                                                    </div>
                                                ) : (
                                                    <div className={styles.booking_details_section1_left2}>
                                                        {selectedDate && formatDate(selectedDate) || 'Select Date'}
                                                    </div>
                                                )}
                                            </div>

                                            {showDates ? (
                                                <div className={styles.booking_details_section1_right}>
                                                    <MdArrowDropDown />
                                                </div>
                                            ) : (
                                                <div className={styles.booking_details_section1_right}>
                                                    Edit
                                                </div>
                                            )}
                                        </div>
                                    </div>

                                    {showDates && (
                                        <div className={styles.dates_container2}>
                                            {!showCalendar ? (
                                                <>
                                                    {availableDates.map((date, index) => {
                                                        const today = new Date();
                                                        const tomorrow = new Date(today);
                                                        tomorrow.setDate(today.getDate() + 1);

                                                        // Check if the date is "TODAY" or "TOMORROW"
                                                        const isToday = formatDate(date) === formatDate(today);
                                                        const isTomorrow = formatDate(date) === formatDate(tomorrow);

                                                        return (
                                                            <button key={index} className={styles.date_button2} onClick={() => handleDateSelect(date)}>
                                                                {isToday
                                                                    ? "TODAY"
                                                                    : isTomorrow
                                                                        ? "TOMORROW"
                                                                        : formatDate(date)}
                                                            </button>
                                                        );
                                                    })}
                                                </>
                                            ) : (
                                                <div className={styles.calendar_container2}>
                                                    <Calendar
                                                        onChange={(date) => {
                                                            handleDateSelect(date);  // Close the calendar after selecting a date
                                                        }}
                                                        value={selectedDate}
                                                        tileDisabled={({ date }) => isDisabledDate(date)}
                                                    />
                                                </div>
                                            )}
                                            {/* <button className={styles.date_button3} onClick={() => setShowCalendar(!showCalendar)}>
                                    {showCalendar ? 'Back to Dates...' : 'More Dates...'}
                                </button> */}

                                            {availableDates?.length > 7 && (
                                                <>
                                                    {showCalendar ? (
                                                        <button className={styles.date_button4} onClick={() => setShowCalendar(!showCalendar)}>
                                                            Back to Dates...
                                                        </button>
                                                    ) : (
                                                        <button className={styles.date_button3} onClick={() => setShowCalendar(!showCalendar)}>
                                                            More Dates...
                                                        </button>
                                                    )}
                                                </>
                                            )}
                                        </div>
                                    )}

                                </div>

                                <div className={styles.booking_details_section_upper} onClick={handleShowHoles}>
                                    <div className={styles.booking_details_section1}>
                                        <div className={styles.booking_details_section1_left}>
                                            <div className={styles.booking_details_section1_left1}>
                                                <MdGolfCourse />
                                            </div>
                                            <div className={styles.booking_details_section1_left2}>
                                                {hole === null ? 'Select Hole' : hole + " Holes"}
                                            </div>
                                        </div>

                                        {showHoles ? (
                                            <div className={styles.booking_details_section1_right}>
                                                <MdArrowDropDown />
                                            </div>
                                        ) : (
                                            <div className={styles.booking_details_section1_right}>
                                                Edit
                                            </div>
                                        )}
                                    </div>

                                </div>
                                {showHoles && (
                                    <div className={styles.booking_details_section_upper2}>
                                        <div className={styles.booking_details_section2}>
                                            <div className={styles.booking_details_section1_heading}>
                                                How many holes do you want to play?
                                            </div>
                                            <div className={styles.booking_details_section1_subheading}>
                                                <div className={styles.booking_details_section1_heading_line}></div>
                                            </div>
                                        </div>
                                        <div className={styles.booking_details_section2}>
                                            <div className={styles.hole_buttons_container}>
                                                <div className={`${promotion.HoleName === "18" ? styles.hole_button_active : styles.hole_button}`} onClick={() => setSelectedHole(hole)}>
                                                    18 Holes
                                                </div>
                                                <div className={`${promotion.HoleName === "9" ? styles.hole_button_active : styles.hole_button}`} onClick={() => setSelectedHole(hole)}>
                                                    9 Holes
                                                </div>
                                            </div>
                                            <div className={styles.hole_buttons_container2}>
                                                <div className={styles.hole_button_submit} onClick={handleHoleSelect}>
                                                    Continue
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}

                                <div className={styles.booking_details_section_upper} onClick={handleShowPlayers}>
                                    <div className={styles.booking_details_section1}>
                                        <div className={styles.booking_details_section1_left}>
                                            <div className={styles.booking_details_section1_left1}>
                                                <HiUsers />
                                            </div>
                                            {players === 0 ? (
                                                <div className={styles.booking_details_section1_left2}>
                                                    Select Players
                                                </div>) : (
                                                <div className={styles.booking_details_section1_left2}>
                                                    {players} player{players === 1 ? '' : 's'}
                                                </div>)}
                                            <div className={styles.booking_details_section1_left3}>
                                                {Array.from({ length: players }, (_, index) => (
                                                    <RiCircleFill key={index} />
                                                ))}
                                            </div>
                                        </div>
                                        {showPlayers ? (
                                            <div className={styles.booking_details_section1_right}>
                                                <MdArrowDropDown />
                                            </div>
                                        ) : (
                                            <div className={styles.booking_details_section1_right}>
                                                Edit
                                            </div>
                                        )}
                                    </div>
                                </div>

                                {showPlayers && (
                                    <div className={styles.booking_details_section_upper2}>
                                        <div className={styles.booking_details_section2}>
                                            <div className={styles.booking_details_section1_heading}>
                                                How many golfers are in your party?
                                            </div>
                                            <div className={styles.booking_details_section1_subheading}>
                                                <div className={styles.booking_details_section1_heading_line}></div>
                                            </div>
                                        </div>
                                    </div>
                                )}

                                {showPlayers && (
                                    <div className={styles.booking_details_section_upper2}>
                                        <div className={styles.booking_details_section3}>
                                            <div className={styles.booking_details_section3_inner}>
                                                {[1, 2, 3, 4].map((num) => (
                                                    <div
                                                        key={num}
                                                        className={
                                                            players === num
                                                                ? styles.block_page_container21_active
                                                                : styles.block_page_container21
                                                        }
                                                        onClick={() => { setBlockToggleShowPlayers(true); setPlayers(num); }}
                                                    >
                                                        {num}
                                                    </div>
                                                ))}
                                            </div>
                                            {players > 0 && (
                                                <div className={styles.muna_outer}>
                                                    <div className={styles.muna_inner}></div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                )}

                                {selectedPlayers > 0 && showPlayers && (
                                    <div className={styles.booking_details_section_upper2}>
                                        <div className={styles.booking_details_section2}>
                                            <div className={styles.booking_details_section1_heading}>
                                                Please select your player category
                                            </div>
                                            <div className={styles.booking_details_section1_subheading}>
                                                <div className={styles.booking_details_section1_heading_line}></div>
                                            </div>
                                        </div>
                                    </div>
                                )}

                                {showPlayers && (
                                    <div className={styles.booking_details_section_upper2}>
                                        <div className={styles.booking_details_section3}>
                                            {Array.from({ length: players }, (_, index) => (
                                                <div className={styles.booking_details_section12} key={index}>
                                                    <select
                                                        className={styles.players_details_container}
                                                        value={selectedPlayers[index]?.category || ''}
                                                        onChange={(e) => { setBlockToggleShowPlayers(true); handlePlayerCategoryChange(index, e.target.value); }}
                                                    >
                                                        <option value="Public">Public</option>
                                                        <option value="Members">Members</option>
                                                        <option value="Thai Residents">Thai Residents</option>
                                                    </select>
                                                </div>
                                            ))}

                                            {players > 0 && (
                                                <div
                                                    className={styles.hole_button_submit2}
                                                    onClick={handleSelectPlayers}
                                                >
                                                    Continue
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                )}

                                <div className={styles.booking_details_section_upper} onClick={handleShowExtras}>
                                    <div className={styles.booking_details_section1}>
                                        <div className={styles.booking_details_section1_left}>
                                            <div className={styles.booking_details_section1_left1}>
                                                <RiStickyNoteAddFill />
                                            </div>
                                            <div className={styles.booking_details_section1_left2}>
                                                Extras
                                            </div>
                                        </div>
                                        {showExtras ? (
                                            <div className={styles.booking_details_section1_right}>
                                                <MdArrowDropDown />
                                            </div>
                                        ) : (
                                            <div className={styles.booking_details_section1_right}>
                                                Edit
                                            </div>
                                        )}
                                    </div>
                                </div>

                                {showExtras && (
                                    <div className={styles.booking_details_section_upper2}>
                                        <div className={styles.booking_details_section2}>
                                            <div className={styles.booking_details_section1_heading}>
                                                Please select if you want any extras
                                            </div>
                                            <div className={styles.booking_details_section1_subheading}>
                                                <div className={styles.booking_details_section1_heading_line}></div>
                                            </div>
                                        </div>
                                        <div className={styles.booking_details_section2}>
                                            <div className={styles.booking_extras_container}>
                                                <div className={styles.extra_booking_div}>
                                                    <div className={styles.extra_booking_div_left}>
                                                        <div className={styles.checkbox_container}>
                                                            <div className={styles.checkbox_group}>
                                                                <input
                                                                    className={styles.checkbox_input}
                                                                    type="checkbox"
                                                                    id="checkbox1"
                                                                    checked={cart}
                                                                    onChange={() => { setCart(!cart); setBlockToggleShowFromExtras(true) }}
                                                                />
                                                                <label htmlFor="checkbox1" className={styles.checkbox_label}>Cart</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className={styles.extra_booking_div_right}>
                                                        <div className={styles.extra_price}>
                                                            {/* &#3647; 250 */}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className={styles.extra_booking_div}>
                                                    <div className={styles.extra_booking_div_left}>
                                                        <div className={styles.checkbox_container}>
                                                            <div className={styles.checkbox_group}>
                                                                <input
                                                                    className={styles.checkbox_input}
                                                                    type="checkbox"
                                                                    id="checkbox2"
                                                                    checked={buggy}
                                                                    onChange={() => { setBlockToggleShowFromExtras(true); setBuggy(!buggy); }}
                                                                />
                                                                <label htmlFor="checkbox2" className={styles.checkbox_label}>Buggy</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className={styles.extra_booking_div_right}>
                                                        <div className={styles.extra_price}>
                                                            {/* &#3647; 300 */}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className={styles.extra_booking_div}>
                                                    <div className={styles.extra_booking_div_left}>
                                                        <div className={styles.checkbox_container}>
                                                            <div className={styles.checkbox_group}>
                                                                <input
                                                                    className={styles.checkbox_input} promotion
                                                                    type="checkbox"
                                                                    id="checkbox3"
                                                                    checked={caddy}
                                                                    onChange={() => { setBlockToggleShowFromExtras(true); setCaddy(!caddy); }}
                                                                />
                                                                <label htmlFor="checkbox3" className={styles.checkbox_label}>Caddy</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className={styles.extra_booking_div_right}>
                                                        <div className={styles.extra_price}>
                                                            {/* &#3647; 170 */}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className={styles.hole_buttons_container2}>
                                                <div className={styles.hole_button_submit} onClick={handleExtrasSelect}>
                                                    Continue
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}

                                <div className={styles.booking_details_section_upper} onClick={handleShowTime}>
                                    <div className={styles.booking_details_section1}>
                                        <div className={styles.booking_details_section1_left}>
                                            <div className={styles.booking_details_section1_left1}>
                                                <FaClock />
                                            </div>
                                            <div className={styles.booking_details_section1_left2}>
                                                {selectedTeeTime === null ? 'Select Time' : selectedTeeTime.StartTeeTime}
                                            </div>
                                        </div>

                                        {showTime ? (
                                            <div className={styles.booking_details_section1_right}>
                                                <MdArrowDropDown />
                                            </div>
                                        ) : (
                                            <div className={styles.booking_details_section1_right}>
                                                Edit
                                            </div>
                                        )}
                                    </div>
                                </div>

                                {showTime && (
                                    <div className={styles.booking_details_section_upper2}>
                                        {players === 0 ? (
                                            <>
                                                <div className={styles.booking_time_section}>
                                                    <div className={styles.booking_details_section1_heading}>
                                                        <div className={styles.booking_details_section1_heading}>
                                                            Please select how many players
                                                        </div>
                                                        <div className={styles.booking_details_section1_subheading}>
                                                            <div className={styles.booking_details_section1_heading_line}></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        ) : (
                                            <div className={styles.booking_time_section}>
                                                <div className={styles.booking_details_section1_heading}>
                                                    <div className={styles.booking_details_section1_heading}>
                                                        What time would you like to play?
                                                    </div>
                                                    <div className={styles.booking_details_section1_subheading}>
                                                        <div className={styles.booking_details_section1_heading_line}></div>
                                                    </div>
                                                </div>

                                                {hole === null ? (
                                                    <>
                                                        <div className={styles.booking_time_div} style={{ marginBottom: '20px', fontSize: '13px' }}>
                                                            Please select hole type first
                                                        </div>
                                                    </>
                                                ) : (
                                                    <div className={styles.booking_time_div}>
                                                        <>
                                                            {promotion?.timeSheet?.length === 0 && (
                                                                <>
                                                                    No tee time left
                                                                </>
                                                            )}
                                                            <>
                                                                <div className={styles.time_div_inner}>
                                                                    <>
                                                                        <div className={styles.time_div_inner_left}>
                                                                            <div className={styles.time_div_inner_left_inner_left}>
                                                                                {promotion?.PromotionName}
                                                                                <span className={styles.slab_time}>
                                                                                    &nbsp; ({promotion?.TimeSheet[0]?.StartTeeTime} - {promotion?.TimeSheet[promotion?.TimeSheet?.length - 1]?.StartTeeTime})
                                                                                </span>
                                                                            </div>
                                                                            <div className={styles.time_div_inner_left_inner_right}>
                                                                                {/* {expandedSlab === item.SlabID ? <IoMdArrowDropdown /> : <IoMdArrowDropup />} */}
                                                                            </div>
                                                                        </div>
                                                                    </>
                                                                </div>

                                                                <>
                                                                    {promotion?.TimeSheet?.length > 0 && promotion?.TimeSheet?.map((tee) => (

                                                                        <div className={styles.time_div_inner2} key={tee.TeeTimeID}>
                                                                            <div className={tee.TeeTimeID === selectedTeeTime?.TeeTimeID ? styles.time_div_inner_left2_active : styles.time_div_inner_left2}>
                                                                                <div className={styles.time_div_inner_left2_inner}>
                                                                                    {tee.StartTeeTime}
                                                                                </div>
                                                                            </div>
                                                                            {handleShowJoin(players, tee.TotalBookedGolfers, promotion.TotalGolfers) ? (
                                                                                <div className={styles.time_div_inner_right2} onClick={() => handleTimeClick(tee, promotion.PromotionPrice, selectedPlayers.length)}>

                                                                                    <div className={styles.time_div_inner_right2_inner}>
                                                                                        <div className={styles.time_div_inner_right2_inner4}>
                                                                                            <FaUsers />
                                                                                        </div>
                                                                                        <div className={styles.time_div_inner_right2_inner2}>
                                                                                            {promotion.TotalGolfers - tee.TotalBookedGolfers} Left
                                                                                        </div>
                                                                                    </div>

                                                                                    <div className={styles.time_div_inner_right2_inner}>
                                                                                        <div className={styles.time_div_inner_right2_inner}>
                                                                                            &#3647; {promotion.PromotionPrice * selectedPlayers.length}
                                                                                        </div>
                                                                                        <div className={styles.time_div_inner_right2_inner2}>
                                                                                            <MdOutlineKeyboardArrowRight />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            ) : (
                                                                                <div className={styles.time_div_inner_right2_disabled}>

                                                                                    <div className={styles.time_div_inner_right2_inner}>
                                                                                        <div className={styles.time_div_inner_right2_inner4}>
                                                                                            <FaUsers />
                                                                                        </div>
                                                                                        <div className={styles.time_div_inner_right2_inner2}>
                                                                                            {promotion.TotalGolfers - tee.TotalBookedGolfers} Left
                                                                                        </div>
                                                                                    </div>

                                                                                    <div className={styles.time_div_inner_right2_inner}>
                                                                                        <div className={styles.time_div_inner_right2_inner}>
                                                                                            &#3647; {promotion.PromotionPrice}
                                                                                        </div>
                                                                                        <div className={styles.time_div_inner_right2_inner2}>
                                                                                            <MdOutlineKeyboardArrowRight />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            )}
                                                                        </div>
                                                                    ))}
                                                                </>
                                                            </>
                                                        </>
                                                    </div>
                                                )}
                                            </div>
                                        )}
                                    </div>
                                )}

                                <div className={styles.booking_details_right}>
                                    {accessToken ? (
                                        <button className={styles.booking_details_right_button} onClick={handleBookTeeTime}>Book Tee Time</button>
                                    ) : (
                                        <button className={styles.booking_details_right_button} onClick={handleSelectLoginAndContinue}>Continue With Login</button>
                                    )}
                                </div>
                            </div>
                        </div>
                    )}

                    {showLogin && (
                        <div className={styles.login_container_div_2}>
                            <div className={styles.close_button_container}>
                                <AiOutlineClose onClick={handleCloseLogin} />
                            </div>
                            <div className={loginStyles.login_heading_div}>
                                Login
                            </div>

                            <div className={loginStyles.login_form_container}>
                                <div className={loginStyles.input_group}>
                                    <label className={loginStyles.input_label_login}>Email</label>
                                    <input className={loginStyles.input_field_login} type="text" placeholder='Enter your email' onChange={(e) => setEmail(e.target.value)} value={email} />
                                </div>

                                <div className={loginStyles.input_group}>
                                    <label className={loginStyles.input_label_login}>PIN</label>
                                    <input className={loginStyles.input_field_login} type="password" placeholder='Enter your PIN' onChange={(e) => setPin(e.target.value)} value={pin}
                                        onInput={(e) => { e.target.value = e.target.value.replace(/[^0-9]/g, '').slice(0, 4); }} />
                                </div>

                                <div className={loginStyles.forgot_password_link}>
                                    <span style={{ color: '#007bff', cursor: 'pointer' }} onClick={goToForgotPassword}>Forgot Your PIN?</span>
                                </div>

                                <div className={loginStyles.login_button_container}>
                                    <button className={loginStyles.login_button} onClick={handleLogin}>Login</button>
                                </div>

                                <div className={loginStyles.register_link}>
                                    Don't have an account? <span style={{ color: '#007bff', cursor: 'pointer' }} onClick={goToRegister}>Register here</span>
                                </div>
                            </div>
                        </div>
                    )}

                    {showRegister && (
                        <div className={styles.login_container_div_2}>
                            <div className={styles.close_button_container}>
                                <AiOutlineClose onClick={handleCloseLogin} />
                            </div>
                            <div className={loginStyles.login_heading_div}>
                                Register
                            </div>

                            <div className={registerStyles.register_form_container}>
                                <div className={registerStyles.input_group}>
                                    <label className={registerStyles.input_label_register}>Name</label>
                                    <input className={registerStyles.input_field_register} type="text" placeholder='Full Name' onChange={(e) => setName(e.target.value)} value={name} />
                                </div>

                                <div className={registerStyles.input_group}>
                                    <label className={registerStyles.input_label_register}>Email</label>
                                    <input className={registerStyles.input_field_register} type="text" placeholder='Email Address' onChange={(e) => setEmail(e.target.value)} value={email} />
                                </div>

                                <div className={registerStyles.input_group}>
                                    <label className={registerStyles.input_label_register}>PIN</label>
                                    <input className={registerStyles.input_field_register} type="password" maxLength="4"
                                        pattern="\d{4}"
                                        onInput={(e) => {
                                            e.target.value = e.target.value.replace(/[^0-9]/g, '').slice(0, 4);
                                        }} placeholder='Enter New 4 Digit PIN'
                                        onChange={(e) => setPin(e.target.value)} />
                                </div>

                                <div className={registerStyles.input_group}>
                                    <label className={registerStyles.input_label_register}>Phone Number</label>
                                    <input className={registerStyles.input_field_register} type="text" placeholder='Phone Number'
                                        pattern="[0-9]{10}"
                                        onInput={(e) => {
                                            e.target.value = e.target.value.replace(/[^0-9]/g, '').slice(0, 10);
                                        }}
                                        onChange={(e) => setPhoneNumber(e.target.value)} value={phoneNumber}
                                    />
                                </div>

                                <div className={registerStyles.input_group}>
                                    <label className={registerStyles.input_label_register}>Country</label>
                                    <CustomDropdown
                                        options={options}
                                        value={selectedValue}
                                        onChange={setSelectedValue}
                                    />
                                </div>

                                <div className={registerStyles.register_button_container}>
                                    <button className={registerStyles.register_button} onClick={handleRegister}>Register</button>
                                </div>

                                <div className={loginStyles.login_link}>
                                    Already have an account? <span style={{ color: '#007bff', cursor: 'pointer' }} onClick={goToLogin}>Login here</span>
                                </div>
                            </div>
                        </div>
                    )}

                    {showForgotPassword && (
                        <div className={styles.login_container_div_2}>
                            <div className={styles.close_button_container}>
                                <AiOutlineClose onClick={handleCloseLogin} />
                            </div>
                            <div className={loginStyles.login_heading_div}>
                                Forgot PIN
                            </div>

                            <div className={forgotStyles.forgot_password_form_container}>
                                <div className={forgotStyles.input_group}>
                                    <label className={forgotStyles.input_label_forgot_password}>Email</label>
                                    <input className={forgotStyles.input_field_forgot_password} type="email" placeholder='Enter your email' onChange={(e) => setEmail(e.target.value)} value={email} />
                                </div>

                                <div className={forgotStyles.forgot_password_button_container}>
                                    <button className={forgotStyles.forgot_password_button} onClick={goToOtpVerify}>Send Reset OTP</button>
                                </div>
                            </div>

                            <div className={styles.login_link2}>
                                Already know your PIN? <span style={{ color: '#007bff', cursor: 'pointer' }} onClick={goToLogin}>Login here</span>
                            </div>
                        </div>
                    )}

                    {showPin && (
                        <div className={styles.login_container_div_2}>
                            <div className={styles.close_button_container}>
                                <AiOutlineClose onClick={handleCloseLogin} />
                            </div>
                            <div className={loginStyles.login_heading_div}>
                                Forgot PIN
                            </div>

                            <div className={forgotStyles.forgot_password_form_container}>
                                <div className={forgotStyles.input_group}>
                                    <label className={forgotStyles.input_label_forgot_password}>OTP</label>
                                    <input className={forgotStyles.input_field_forgot_password} type="text" placeholder='Enter OTP' onChange={(e) => setOtp(e.target.value)} value={otp} onInput={(e) => { e.target.value = e.target.value.replace(/[^0-9]/g, '').slice(0, 6); }} />
                                </div>

                                <div className={forgotStyles.input_group}>
                                    <label className={forgotStyles.input_label_forgot_password}>PIN</label>
                                    <input className={forgotStyles.input_field_forgot_password} type="password" placeholder='Enter PIN' onChange={(e) => setPin(e.target.value)} value={pin} onInput={(e) => { e.target.value = e.target.value.replace(/[^0-9]/g, '').slice(0, 4); }} />
                                </div>

                                <div className={forgotStyles.input_group}>
                                    <label className={forgotStyles.input_label_forgot_password}>Confirm PIN</label>
                                    <input className={forgotStyles.input_field_forgot_password} type="password" placeholder='Confirm PIN' onChange={(e) => setConfirmPin(e.target.value)} value={confirmPin} onInput={(e) => { e.target.value = e.target.value.replace(/[^0-9]/g, '').slice(0, 4); }} />
                                </div>

                                <div className={forgotStyles.forgot_password_button_container}>
                                    <button className={forgotStyles.forgot_password_button} onClick={forgotPassword}>Reset Password</button>
                                </div>
                            </div>

                            <div className={styles.login_link2}>
                                Already know your PIN? <span style={{ color: '#007bff', cursor: 'pointer' }} onClick={goToLogin}>Login here</span>
                            </div>
                        </div>
                    )}
                </>
            )}
        </>
    )
}

export default BookPromotion
