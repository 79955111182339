import React, { useState, useEffect } from 'react';
import axios from 'axios';
import languageStyles from './LanguagePage.module.css';
import { useNavigate, useParams } from 'react-router-dom';
import { API_URL } from '../constant';
import Loader from '../Components/Loader';
import Swal from 'sweetalert2';
import refreshAccessToken from '../refreshAccessToken';
import { useSelector, useDispatch } from 'react-redux';
import { checkAccessToken } from '../features/auth/authSlice';
import { RWebShare } from "react-web-share";

const EventPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();

  const accessToken = useSelector((state) => state.auth.accessToken);
  const refreshToken = useSelector((state) => state.auth.refreshToken);

  const [loading, setLoading] = useState(false);
  const [eventDetails, setEventDetails] = useState({});
  const [alreadyRegistered, setAlreadyRegistered] = useState(0);

  const fetchEventDetails = async (id) => {
    try {
      setLoading(true);
      const response = await axios.get(`${API_URL}/api/getEventById/${id}`);
      setEventDetails(response.data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  const checkAlreadyRegistered = async () => {
    try {
      setLoading(true);
      dispatch(checkAccessToken());
      const response = await axios.get(`${API_URL}/api/checkEventBookedByUser/${id}`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}`
        }
      });

      if (response.status === 200) {
        setAlreadyRegistered(0);
      }
    } catch (error) {
      console.log(error);

      if (error.response?.status === 400) {
        setAlreadyRegistered(2);
      }

      if (error.response?.status === 409) {
        setAlreadyRegistered(1);
      }
    } finally {
      setLoading(false);
    }
  }

  const formatDate = (date) => {
    // return data like Friday December 1, 2024
    const options = { weekday: 'long', day: 'numeric', month: 'long', year: 'numeric' };
    return date.toLocaleDateString('en-US', options);
  }

  useEffect(() => {
    fetchEventDetails(id);
    // checkAlreadyRegistered();
    dispatch(checkAccessToken());
  }, [id]);

  useEffect(() => {
    checkAlreadyRegistered();
  }, [accessToken]);

  const handleBookNow = async () => {
    if (accessToken === null) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Please login to book this event',
        confirmButtonText: 'OK',
      }).then((result) => {
        if (result.isConfirmed) {
          navigate('/login');
        }
      });
      return;
    }

    navigate('/event_booking/' + id);
  }

  const goToPlayerDetails = () => {
    navigate('/event_booking_done/' + id);
  }

  return (
    <>
      {loading ? <Loader /> : (
        <div className={languageStyles.event_page_container}>
          <div className={languageStyles.event_page_title}>
            <p>{eventDetails.EventName}</p>
            <p style={{ fontSize: '17px' }}>{formatDate(new Date(eventDetails.EventDate))}</p>
          </div>
          <div className={languageStyles.event_page_description}>
            <p> {eventDetails.EventType} </p>
            <p>{eventDetails.EventTitle} - {eventDetails.StartTime}</p>
            <p>{eventDetails.EntryFee} baht per team</p>
            <p>Teams of {eventDetails.TeamSize}</p>
            <p> {eventDetails.TotalSlots - eventDetails.TotalBookings === 0 ? 'No places left' : `${eventDetails.TotalSlots - eventDetails.TotalBookings}/${eventDetails.TotalSlots} places left`} </p>

          </div>
          <div className={languageStyles.event_page_buttons}>
            {alreadyRegistered === 0 ? (
              <div className={languageStyles.event_page_button} onClick={handleBookNow}>
                Book Now
              </div>
            ) : (
              <>
              {alreadyRegistered === 1 ? (
                <div className={languageStyles.event_page_button} onClick={goToPlayerDetails}>
                  View Player Details
                </div>
              ) : (
                <div className={languageStyles.event_page_button_disabled}>
                  No Places Left
                </div>
              )}
              </>
            )}
            <div className={languageStyles.event_page_button} onClick={() => navigate('/event_details/' + id)}>
              See All Details
            </div>
            <RWebShare
            data={{
              text: eventDetails.EventName,
              url: window.location.href,
              title: eventDetails.EventName,
            }}
          >
            <div className={languageStyles.event_page_button} >
              Send to a Friend
            </div>
          </RWebShare>
          </div>
        </div>
      )}
    </>
  )
}

export default EventPage
