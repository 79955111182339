import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styles from './PromotionDetails.module.css';
import axios from 'axios';
import { API_URL } from '../constant';
import './quill.css';

const PromotionDetails = () => {
    const params = useParams();
    const { id } = params;
    const navigate = useNavigate();

    const [promotionDetails, setPromotionDetails] = useState([]);
    const [descriptionDetails, setDescriptionDetails] = useState("");

    const getPromotionDetails = async () => {
        try {
            const response = await axios.get(`${API_URL}/api/getPromotionById/${id}`);
            setPromotionDetails(response.data);
            setDescriptionDetails(response.data.PromotionDescription.replace(/&nbsp;/g, ' '));
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        getPromotionDetails();
    }, []);

    return (
        <div className={styles.promotion_details_container}>
            <div className={styles.promotion_details_heading}>
                {promotionDetails.PromotionName}
            </div>

            <div className={styles.promotion_details_heading2}>
                <button className={styles.promotion_details_button} onClick={() => navigate('/book-promotion' + `/${id}`)}>Book Tee Time</button>
            </div>

            <div className={styles.promotion_details_container_inner}>
                <div
                    className={`${styles.promotion_details_description} ql-editor`}
                    
                    dangerouslySetInnerHTML={{ __html: descriptionDetails }}
                >
                </div>
            </div>
        </div>
    )
}

export default PromotionDetails
