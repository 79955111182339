import React, { useState, useEffect } from 'react';
import styles from './Bookings.module.css';
import Loader from '../Components/Loader';
import { GrAscend, GrDescend } from "react-icons/gr";
import Swal from 'sweetalert2';
import axios from 'axios';
import { API_URL } from '../constant';
import refreshAccessToken from '../refreshAccessToken';
import { useSelector } from 'react-redux';

const Bookings = () => {
    const accessToken = useSelector((state) => state.auth.accessToken);

    const [loading, setLoading] = useState(false);
    const [activeTab, setActiveTab] = useState('Upcoming');
    const [bookings, setBookings] = useState([]);

    const getBookings = async () => {
        try {
            setLoading(true);

            if (!accessToken) throw new Error('Access token not found');


            const response = await axios.get(`${API_URL}/api/getBookingByUserId`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`,
                },
            });

            if (response.status === 200) {
                setBookings(response.data);
            }
        } catch (error) {
            console.error(error);

            if (error.response?.status === 403) {
                await refreshAccessToken();
            } else {
                Swal.fire("Error", "Failed to fetch bookings", "error");
            }
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        getBookings();
    }, [accessToken]);

    const handleTabChange = (tab) => {
        setActiveTab(tab);
    };

    const filteredBookings = bookings.filter((booking) => {
        const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

        // Get current date and time in user's timezone
        const currentDate = new Date().toLocaleString("en-US", { timeZone: userTimezone });
        const currentDateTime = new Date(currentDate); // Convert back to Date object

        // Combine booking date and time
        const bookingDateTimeStr = `${booking.Date} ${booking.StartTeeTime}`;
        const bookingDateTime = new Date(bookingDateTimeStr);

        console.log("Current Date:", currentDateTime);
        console.log("Booking DateTime:", bookingDateTime);

        if (activeTab === 'Upcoming') {
            return bookingDateTime >= currentDateTime;
        }
        return bookingDateTime < currentDateTime;
    });

    const formatDate = (date) => {
        return new Date(date).toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
        });
    };

    return (
        <>
            {loading ? (
                <Loader />
            ) : (
                <div className={styles.booking_container}>
                    <div className={styles.booking_heading}>BOOKING DETAILS</div>

                    <div className={styles.tabs}>
                        <button
                            className={`${styles.tab} ${activeTab === 'Upcoming' ? styles.active : ''}`}
                            onClick={() => handleTabChange('Upcoming')}
                        >
                            Upcoming
                        </button>
                        <button
                            className={`${styles.tab} ${activeTab === 'Previous' ? styles.active : ''}`}
                            onClick={() => handleTabChange('Previous')}
                        >
                            Completed
                        </button>
                    </div>

                    <div className={styles.bookings_list}>
                        {filteredBookings.length > 0 ? (
                            filteredBookings
                                .filter(booking => booking.BookingStatus !== 'Cancel') // Filter out canceled bookings
                                .map((booking) => (
                                    <div key={booking.BookingID} className={styles.booking_card}>
                                        <div className={styles.booking_card_header}>
                                            <span className={styles.booking_title}>{booking.Date} {booking.StartTeeTime}</span>
                                            <span className={`${styles.booking_status} ${booking.BookingStatus.toLowerCase()}`}>
                                                {booking.BookingStatus}
                                            </span>
                                        </div>
                                        <div className={styles.booking_card_body}>
                                            <div className={styles.booking_id}>Booking ID: {booking.BookingID}</div>
                                            <div className={styles.booking_players}>Players: {booking.TotalGolfers}</div>
                                            <div className={styles.booking_players}>Hole: {booking.HoleName}</div>
                                            <div className={styles.booking_price}>Price: &#3647; {booking.BookingPricing}</div>
                                            <div className={styles.booking_extras}>
                                                Extras: {booking.Caddy || booking.Cart || booking.Buggy ? (
                                                    [
                                                        booking.Caddy > 0 && "Caddy",
                                                        booking.Cart > 0 && "Cart",
                                                        booking.Buggy > 0 && "Buggy",
                                                    ]
                                                        .filter(Boolean) // Removes any `false` or `undefined` values
                                                        .join(", ")
                                                ) : (
                                                    "No additional services"
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                ))
                        ) : (
                            <div className={styles.no_bookings}>No bookings available</div>
                        )}
                    </div>
                </div>
            )}
        </>
    );
};

export default Bookings;
