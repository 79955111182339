import React, { useState } from 'react';
import styles from './ForgotPassword.module.css';
import axios from 'axios';
import { API_URL } from '../constant';
import Swal from 'sweetalert2';
import Loader from '../Components/Loader';
import { useNavigate } from 'react-router-dom';

const ForgotPassword = () => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [showEmail, setShowEmail] = useState(true);
  const [showPin, setShowPin] = useState(false);
  const [otp, setOtp] = useState('');
  const [pin, setPin] = useState('');
  const [confirmPin, setConfirmPin] = useState('');

  const goToOtpVerify = async () => {
    if (email.trim() === '') {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Please enter your email",
      });
      return;
    }

    if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,10})+$/.test(email)) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Please enter a valid email",
      });
      return;
    }

    try {
      setLoading(true);

      const response = await axios.post(`${API_URL}/api/forgot-password`, { email });
      
      if (response.status === 200) {
        Swal.fire({
          icon: "success",
          title: "Success",
          text: response.data.message,
        });

        setShowEmail(false);
        setShowPin(true);
      }
      
    } catch (error) {
      console.log(error);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: error.response.data.message,
      });
    } finally {
      setLoading(false);
    }
  }

  const forgotPassword = async () => {
    if (pin.trim() === '') {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Please enter your PIN",
      });
      return;
    }

    if (confirmPin.trim() === '') {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Please confirm your PIN",
      });
      return;
    }

    if (pin !== confirmPin) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "PINs do not match",
      });
      return;
    }

    if (pin.length !== 4) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Please enter a valid PIN",
      });
      return;
    }

    if (!/^\d{4}$/.test(pin)) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Please enter a valid PIN",
      });
      return;
    }

    if (!/^\d{4}$/.test(confirmPin)) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Please enter a valid PIN",
      });
      return;
    }

    if (otp.trim() === '') {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Please enter your OTP",
      });
      return;
    }

    if (otp.length !== 6) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Please enter a valid OTP",
      });
      return;
    }

    if (!/^\d{6}$/.test(otp)) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Please enter a valid OTP",
      });
      return;
    }

    try {
      setLoading(true);
      
      const response = await axios.post(`${API_URL}/api/reset-password`, { email: email, newPassword: pin, otp: otp });

      if (response.status === 200) {
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "Password reset successfully",
        });

        setShowEmail(true);
        setShowPin(false);
        setEmail('');
        setPin('');
        setConfirmPin('');
        setOtp('');
        navigate('/login');
      }

      if (response.status !== 200) {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: response.data.message,
        });
      }
    } catch (error) {
      console.log(error);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: error.response.data.message,
      });
    } finally {
      setLoading(false);
    }
  }

  return (
    <>
      {loading ? <Loader /> : (
        <div className={styles.forgot_password_container_div}>
          <div className={styles.forgot_password_heading_div}>
            Forgot Password
          </div>

          {showEmail && (
            <div className={styles.forgot_password_form_container}>
              <div className={styles.input_group}>
                <label className={styles.input_label_forgot_password}>Email</label>
                <input className={styles.input_field_forgot_password} type="email" placeholder='Enter your email' onChange={(e) => setEmail(e.target.value)} value={email} />
              </div>

              <div className={styles.forgot_password_button_container}>
                <button className={styles.forgot_password_button} onClick={goToOtpVerify}>Send Reset OTP</button>
              </div>
            </div>
          )}

          {showPin && (
            <div className={styles.forgot_password_form_container}>
              <div className={styles.input_group}>
                <label className={styles.input_label_forgot_password}>OTP</label>
                <input className={styles.input_field_forgot_password} type="text" placeholder='Enter OTP' onChange={(e) => setOtp(e.target.value)} value={otp} onInput={(e) => { e.target.value = e.target.value.replace(/[^0-9]/g, '').slice(0, 6); }} />
              </div>

              <div className={styles.input_group}>
                <label className={styles.input_label_forgot_password}>PIN</label>
                <input className={styles.input_field_forgot_password} type="password" placeholder='Enter PIN' onChange={(e) => setPin(e.target.value)} value={pin} onInput={(e) => { e.target.value = e.target.value.replace(/[^0-9]/g, '').slice(0, 4); }} />
              </div>

              <div className={styles.input_group}>
                <label className={styles.input_label_forgot_password}>Confirm PIN</label>
                <input className={styles.input_field_forgot_password} type="password" placeholder='Confirm PIN' onChange={(e) => setConfirmPin(e.target.value)} value={confirmPin} onInput={(e) => { e.target.value = e.target.value.replace(/[^0-9]/g, '').slice(0, 4); }} />
              </div>

              <div className={styles.forgot_password_button_container}>
                <button className={styles.forgot_password_button} onClick={forgotPassword}>Reset Password</button>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default ForgotPassword;