import React from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './Rates.module.css';

const Rates = () => {
    const navigate = useNavigate();
    
  return (
    <div className={styles.rates_container}>
      <div className={styles.rates_heading}>
        RATES
      </div>

      <div className={styles.rates_container_inner}>
        <div className={styles.rates_box}>
            <div className={styles.rates_box_inner_header}>
                Green Fee (Monday to Friday)
            </div>
            <div className={styles.rates_box_inner}>
                18 Holes: 2,000 THB
            </div>
            <div className={styles.rates_box_inner}>
                9 Holes: 1,200 THB
            </div>
        </div>

        <div className={styles.rates_box}>
            <div className={styles.rates_box_inner_header}>
                Green Fee (Weekends and Holidays)
            </div>
            <div className={styles.rates_box_inner}>
                18 Holes: 2,800 THB
            </div>
            <div className={styles.rates_box_inner}>
                9 Holes: 1,700 THB
            </div>
        </div>

        <div className={styles.rates_box}>
            <div className={styles.rates_box_inner}>
                Cady Fee 400 THB
            </div>
        </div>

        <div className={styles.rates_box}>
            <div className={styles.rates_box_inner_header}>
                Golf Cart Rental
            </div>
            <div className={styles.rates_box_inner}>
                8 Holes: 700 THB
            </div>
            <div className={styles.rates_box_inner}>
                9 Holes: 400 THB
            </div>
        </div>

        <div className={styles.rates_box}>
            <div className={styles.rates_box_inner_header}>
                Club Rental
            </div>
            <div className={styles.rates_box_inner}>
                18 Holes: 800 THB
            </div>
            <div className={styles.rates_box_inner}>
                9 Holes: 500 THB
            </div>
        </div>

        <div className={styles.rates_box}>
            <div className={styles.rates_box_inner_header}>
                Green Fee (Monday to Friday)
            </div>
            <div className={styles.rates_box_inner}>
                18 Holes: 2,000 THB
            </div>
            <div className={styles.rates_box_inner}>
                9 Holes: 1,200 THB
            </div>
        </div>

        <div className={styles.rates_box}>
            <div className={styles.rates_box_inner_header}>
                Green Fee (Weekends and Holidays)
            </div>
            <div className={styles.rates_box_inner}>
                18 Holes: 2,800 THB
            </div>
            <div className={styles.rates_box_inner}>
                9 Holes: 1,700 THB
            </div>
        </div>

        <div className={styles.rates_box}>
            <div className={styles.rates_box_inner}>
                Cady Fee 400 THB
            </div>
        </div>
      </div>

      <div className={styles.rates_footer_div}>
        <button className={styles.rates_footer_button} onClick={() => navigate('/book-tee-time')}>Book Tee Time</button>
      </div>
    </div>
  )
}

export default Rates
