import { createSlice } from '@reduxjs/toolkit';
import { Navigate } from 'react-router-dom';

const initialState = {
  language: localStorage.getItem('language'), // Default to English if not set
};

const languageSlice = createSlice({
  name: 'language',
  initialState,
  reducers: {
    setLanguage: (state, action) => {
      state.language = action.payload;
      localStorage.setItem('language', action.payload);
    },
    clearLanguage: (state) => {
      state.language = 'en';
      localStorage.removeItem('language');
    },
  },
});

export const { setLanguage, clearLanguage } = languageSlice.actions;
export default languageSlice.reducer;
