import React from 'react'
import styles from '../Pages/LanguagePage.module.css';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

const HomePage = () => {
    const navigate = useNavigate();

    const accessToken = useSelector((state) => state.auth.accessToken);

    return (
        <div className={styles.home_container}>
            <div className={styles.img_container_home}>
                <img src="Bushwood.png" alt="Bushwood Photo" className={styles.bushwood_img} />
            </div>
            <div className={styles.choose_language_container}>
                <div className={styles.home_page_menu_box} onClick={() => navigate('/booking_details')}>
                    Book Tee Time
                </div>
                <div className={styles.home_page_menu_box} onClick={() => navigate('/upcoming_events')}>
                    Events
                </div>
                <div className={styles.home_page_menu_box} onClick={() => navigate('/view_rates')}>
                    View Rates
                </div>
                <div className={styles.home_page_menu_box} onClick={() => navigate('/promotions')}>
                    Promotions
                </div>
                {accessToken && (
                    <div className={styles.home_page_menu_box} onClick={() => navigate('/bookings')}>
                        My Bookings
                    </div>
                )}
            </div>
        </div>
    )
}

export default HomePage
