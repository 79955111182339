import React from 'react';
import styles from './VerifyOTP.module.css';

const VerifyOTP = () => {
  return (
    <div className={styles.verify_otp_container}>
      <div className={styles.verify_otp_heading}>
        Verify OTP
      </div>

      <div className={styles.verify_otp_content}>
        <div className={styles.verify_otp_form}>
          <div className={styles.verify_otp_input_group}>
            <label className={styles.input_label_verify_otp}>OTP</label>
            <input className={styles.input_field_verify_otp} type="text" placeholder='Enter OTP' />
          </div>

          <div className={styles.verify_otp_input_group}>
            <label className={styles.input_label_verify_otp}>Create New PIN</label>
            <input className={styles.input_field_verify_otp} type="password" maxLength="4" 
              pattern="\d{4}" 
              onInput={(e) => {
                e.target.value = e.target.value.replace(/[^0-9]/g, '').slice(0, 4);
              }} placeholder='Enter New 4 Digit PIN' />
          </div>

          <div className={styles.verify_otp_input_group}>
            <label className={styles.input_label_verify_otp}>Confirm New PIN</label>
            <input className={styles.input_field_verify_otp} type="password" maxLength="4" 
              pattern="\d{4}" 
              onInput={(e) => {
                e.target.value = e.target.value.replace(/[^0-9]/g, '').slice(0, 4);
              }} placeholder='Confirm New 4 Digit PIN' />
          </div>

          <div className={styles.verify_otp_button_container}>
            <button className={styles.verify_otp_button}>Verify OTP</button>
          </div>

          <div className={styles.resend_otp_link}>
            Didn’t receive the OTP? <button className={styles.resend_otp_button}>Resend OTP</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VerifyOTP;