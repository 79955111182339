import React, { useState, useMemo, useEffect } from 'react'
import styles from './Register.module.css';
import countryList from 'react-select-country-list';
import CustomDropdown from '../Components/CustomDropdown';
import Swal from 'sweetalert2';
import { Rings } from 'react-loader-spinner';
import axios from 'axios';
import { API_URL } from '../constant';
import Loader from '../Components/Loader';

const Register = () => {
  const [loading, setLoading] = useState(false);
  const [showRegister, setShowRegister] = useState(true);
  const [showSendMail, setShowSendMail] = useState(false);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [pin, setPin] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [selectedValue, setSelectedValue] = useState("");
  const options = useMemo(() => countryList().getData(), []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleRegister = async () => {
    if (name.trim() === '') {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Please enter your name",
      });
      return;
    }

    if (email.trim() === '') {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Please enter your email",
      });
      return;
    }

    if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,10})+$/.test(email)) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Please enter a valid email",
      });
      return;
    }

    if (pin.trim() === '') {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Please enter your PIN",
      });
      return;
    }

    if (phoneNumber.trim() === '') {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Please enter your phone number",
      });
      return;
    }

    if (selectedValue.trim() === '') {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Please select your country",
      });
      return;
    }

    try {
      setLoading(true);
      const body = {
        "name": name,
        "email": email,
        "password": pin,
        "phone": phoneNumber,
        "country": selectedValue
      }

      const response = await axios.post(`${API_URL}/api/sendActivationLink`, body);

      if (response.status === 200) {
        window.scrollTo(0, 0);
        setShowRegister(false);
        setShowSendMail(true);
      }
    } catch (error) {
      console.log(error);

      if (error.response.status === 400) {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: error.response.data.message,
        });
      }

      if (error.response.status === 404) {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: error.response.data.message,
        });
      }

      if (error.response.status === 500) {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: error.response.data.message,
        });
      }
    } finally {
      setLoading(false);
    }
  }

  console.log(selectedValue);

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div className={styles.register_container_div}>
          <div className={styles.register_heading_div}>
            Register
          </div>

          {showRegister && (
            <div className={styles.register_form_container}>
              <div className={styles.input_group}>
                <label className={styles.input_label_register}>Name</label>
                <input className={styles.input_field_register} type="text" placeholder='Full Name' onChange={(e) => setName(e.target.value)} value={name} />
              </div>

              <div className={styles.input_group}>
                <label className={styles.input_label_register}>Email</label>
                <input className={styles.input_field_register} type="text" placeholder='Email Address' onChange={(e) => setEmail(e.target.value)} value={email} />
              </div>

              <div className={styles.input_group}>
                <label className={styles.input_label_register}>PIN</label>
                <input className={styles.input_field_register} type="password" maxLength="4"
                  pattern="\d{4}"
                  onInput={(e) => {
                    e.target.value = e.target.value.replace(/[^0-9]/g, '').slice(0, 4);
                  }} placeholder='Enter New 4 Digit PIN'
                  onChange={(e) => setPin(e.target.value)} />
              </div>

              <div className={styles.input_group}>
                <label className={styles.input_label_register}>Phone Number</label>
                <input className={styles.input_field_register} type="text" placeholder='Phone Number'
                  pattern="[0-9]{10}"
                  onInput={(e) => {
                    e.target.value = e.target.value.replace(/[^0-9]/g, '').slice(0, 10);
                  }}
                  onChange={(e) => setPhoneNumber(e.target.value)} value={phoneNumber}
                />
              </div>

              <div className={styles.input_group}>
                <label className={styles.input_label_register}>Country</label>
                <CustomDropdown
                  options={options}
                  value={selectedValue}
                  onChange={setSelectedValue}
                />
              </div>

              <div className={styles.register_button_container}>
                <button className={styles.register_button} onClick={handleRegister}>Register</button>
              </div>

              <div className={styles.login_link}>
                Already have an account? <a href='/login' style={{ textDecoration: 'none' , color: 'blue', cursor: 'pointer', fontWeight: '600' }}>Login here</a>
              </div>
            </div>
          )}

          {showSendMail && (
            <div className={styles.register_form_container}>
              <div className={styles.registration_success}>
                Please check your email to verify your account
              </div>
              <div className={styles.register_button_container}>
                <button className={styles.register_button} onClick={() => window.location.reload()}>Change Email</button>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default Register;
