import React, { useEffect, useState, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import styles from './ActivateAccount.module.css';
import { API_URL } from '../constant';
import { useDispatch } from 'react-redux';
import { setAccessToken, setRefreshToken } from '../features/auth/authSlice';
import Loader from '../Components/Loader';

const ActivateAccount = () => {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [status, setStatus] = useState(null); // 'success' or 'error'
  const location = useLocation();
  const navigate = useNavigate();
  const hasActivated = useRef(false); // Ref to track API call

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const token = queryParams.get('token');

    if (token && !hasActivated.current) {
      activateAccount(token);
      hasActivated.current = true; // Set ref to true after API call
    } else if (!token && hasActivated.current) {
      setMessage('Invalid activation link.');
      setStatus('error');
    }
  }, [location.search]);

  const activateAccount = async (token) => {
    try {
      setLoading(true);
      const response = await axios.post(`${API_URL}/api/activateAccountandBooking?token=${token}`);
      if (response.status === 200) {
        const { accessToken, refreshToken } = response.data;
        dispatch(setAccessToken(accessToken));
        dispatch(setRefreshToken(refreshToken));
        setMessage('Your account has been successfully activated!');
        setStatus('success');
      } else {
        setMessage('Account activation failed. Please try again or contact support.');
        setStatus('error');
      }
    } catch (error) {
      console.error('Error activating account:', error);
      setMessage('Account activation failed. Please try again or contact support.');
      setStatus('error');
    } finally {
      setLoading(false);
    }
  };

  const handleRedirect = () => {
    navigate('/');
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div className={styles.activate_account_container}>
          <div className={styles.activate_account_heading}>
            Activate Account
          </div>
          <div className={styles.activate_account_content}>
            <div className={`${styles.activate_account_message} ${status}`}>
              {message}
            </div>
            {status === 'success' && (
              <button className={styles.primary_button} onClick={handleRedirect}>
                Go to Dashboard
              </button>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default ActivateAccount;
