import React, { useState, useEffect, useMemo } from 'react';
import styles from './EventBooking.module.css';
import languageStyle from './LanguagePage.module.css';
import registerStyles from './Register.module.css';
import Loader from '../Components/Loader';
import axios from 'axios';
import { API_URL } from '../constant';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { checkAccessToken } from '../features/auth/authSlice';
import Swal from 'sweetalert2';
import countryList from 'react-select-country-list';
import CustomDropdown from '../Components/CustomDropdown2';

const EventBooking = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { id } = useParams();

    const options = useMemo(() => countryList().getData(), []);

    const accessToken = useSelector((state) => state.auth.accessToken);

    const [loading, setLoading] = useState(false);
    const [eventDetails, setEventDetails] = useState({});
    const [alreadyRegistered, setAlreadyRegistered] = useState(0);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [currentPlayerIndex, setCurrentPlayerIndex] = useState(0);
    const [selectedValue, setSelectedValue] = useState('');
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        country: ''
    });
    const [playerDetails, setPlayerDetails] = useState([]);
    const [error, setError] = useState('');

    const fetchEventDetails = async (id) => {
        try {
            setLoading(true);
            const response = await axios.get(`${API_URL}/api/getEventById/${id}`);
            setEventDetails(response.data);
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    };

    const checkAlreadyRegistered = async () => {
        try {
            setLoading(true);
            dispatch(checkAccessToken());
            const response = await axios.get(`${API_URL}/api/checkEventBookedByUser/${id}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`
                }
            });

            if (response.status === 200) {
                setAlreadyRegistered(0);
            }
        } catch (error) {
            console.log(error);

            if (error.response?.status === 400) {
                setAlreadyRegistered(2);
            }

            if (error.response?.status === 409) {
                setAlreadyRegistered(1);
            }
        } finally {
            setLoading(false);
        }
    };

    const formatDate = (date) => {
        const options = { weekday: 'long', day: 'numeric', month: 'long', year: 'numeric' };
        return date.toLocaleDateString('en-US', options);
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        // Validate inputs
        if (!formData.name.trim() || !formData.email.trim() || !formData.phone.trim() || !formData.country) {
            setError('All fields are required.');
            return;
        }
        if (!/^\d{10}$/.test(formData.phone)) {
            setError('Phone number must be 10 digits.');
            return;
        }

        const newPlayer = {
            EUserName: formData.name,
            EUserEmail: formData.email,
            EUserPhone: formData.phone,
            EUserCountry: formData.country
        };

        const updatedPlayers = [...playerDetails];
        updatedPlayers[currentPlayerIndex] = newPlayer;

        setPlayerDetails(updatedPlayers);
        setFormData({
            name: '',
            email: '',
            phone: '',
            country: ''
        });
        setError('');
        setIsModalOpen(false);
        window.scrollTo(0, 0);
    };

    const openPlayerModal = (index) => {
        setCurrentPlayerIndex(index);
        const existingPlayer = playerDetails[index];
        if (existingPlayer) {
            setFormData({
                name: existingPlayer.EUserName,
                email: existingPlayer.EUserEmail,
                phone: existingPlayer.EUserPhone,
                country: existingPlayer.EUserCountry
            });
        } else {
            setFormData({
                name: '',
                email: '',
                phone: '',
                country: ''
            });
        }
        setIsModalOpen(true);
    };

    const handleBookEvent = async () => {
        try {
            setLoading(true);
            dispatch(checkAccessToken());
            const response = await axios.post(
                `${API_URL}/api/bookEvent/${id}`,
                { "groupMembers": playerDetails },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${accessToken}`
                    }
                }
            );
            if (response.status === 200) {
                Swal.fire({
                    icon: 'success',
                    title: 'Success',
                    text: 'Event booked successfully'
                });
                navigate('/event_page/' + id);
            }
        } catch (error) {
            console.log(error);
            alert('Failed to book the event.');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchEventDetails(id);
        checkAlreadyRegistered();
        dispatch(checkAccessToken());
    }, [id]);

    return (
        <>
            {loading ? <Loader /> : (
                <div className={languageStyle.event_page_container}>
                    <div className={languageStyle.event_page_title}>
                        <p>{eventDetails.EventName}</p>
                        <p style={{ fontSize: '17px' }}>{formatDate(new Date(eventDetails.EventDate))}</p>
                    </div>

                    <div className={styles.event_player_buttons}>
                        {[...Array(eventDetails.TeamSize || 0)].map((_, index) => {
                            const isPlayerComplete = playerDetails[index] &&
                                playerDetails[index].EUserName &&
                                playerDetails[index].EUserEmail &&
                                playerDetails[index].EUserPhone &&
                                playerDetails[index].EUserCountry;

                            return (
                                <button
                                    key={index}
                                    className={`${styles.players_details_button} ${isPlayerComplete ? styles.completed : ''}`}
                                    onClick={() => openPlayerModal(index)}
                                >
                                    Player {index + 1} Details {isPlayerComplete && <span className={styles.green_tick}>✔</span>}
                                </button>
                            );
                        })}
                        <button
                            className={styles.book_event_button}
                            onClick={handleBookEvent}
                            disabled={playerDetails.length !== eventDetails.TeamSize || playerDetails.some(player => !player)}
                        >
                            Book Event
                        </button>
                    </div>

                    {isModalOpen && (
                        <div className={styles.modal_overlay}>
                            <div className={styles.modal_container}>
                                <div className={styles.modal_header}>
                                    <h2>Enter Player Details</h2>
                                    <button className={styles.modal_close} onClick={() => {setIsModalOpen(false); window.scrollTo(0, 0);}}>&times;</button>
                                </div>
                                <form onSubmit={handleSubmit} className={styles.modal_form}>
                                    <input
                                        type='text'
                                        name='name'
                                        placeholder='Name'
                                        value={formData.name}
                                        onChange={handleInputChange}
                                        className={styles.input_group}
                                        required
                                    />
                                    <input
                                        type='email'
                                        name='email'
                                        placeholder='Email'
                                        value={formData.email}
                                        onChange={handleInputChange}
                                        className={styles.input_group}
                                        required
                                    />
                                    <input
                                        type='tel'
                                        name='phone'
                                        placeholder='Phone Number'
                                        value={formData.phone}
                                        onChange={handleInputChange}
                                        className={styles.input_group}
                                        required
                                    />
                                        <CustomDropdown
                                            options={options}
                                            value={formData.country}
                                            onChange={(value) => setFormData({ ...formData, country: value || '' })}
                                            onInputChange={(inputValue) => {
                                                if (!inputValue) {
                                                    setFormData({ ...formData, country: '' });
                                                }
                                            }}
                                            placeholder="Select your country"
                                            isClearable
                                        />
                                    {error && <p className={styles.error_message}>{error}</p>}
                                    <button type='submit' className={styles.modal_submit}>Submit</button>
                                </form>
                            </div>
                        </div>
                    )}
                </div>
            )}
        </>
    );
};

export default EventBooking;
