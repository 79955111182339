import axios from 'axios';
import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import { API_URL } from '../constant';
import styles from '../Pages/LanguagePage.module.css';

const UpcomingEvents = () => {
  const navigate = useNavigate();

  const [upcomingEvents, setUpcomingEvents] = useState([]);

  const fetchUpcamingEvents = async () => {
    try {
      const response = await axios.get(`${API_URL}/api/getAllEvents/1`);
      setUpcomingEvents(response.data);
    } catch (error) {
      console.log(error); 
    }
  }

  useEffect(()=> {
    fetchUpcamingEvents();
  }, []);

  const getMonth = (date) => {
    const monthNames = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"];
    return monthNames[date.getMonth()];
  }

  const formatDate = (date) => {
    // return data like Friday December 1, 2024
    const options = { weekday: 'long', day: 'numeric', month: 'long', year: 'numeric' };
    return date.toLocaleDateString('en-US', options);
  }

  return (
    <div className={styles.upcoming_events_container}>

      <div className={styles.upcoming_events_header}>
        <p>UPCOMING EVENTS</p>
      </div>
      <div className={styles.upcoming_events_content}>
        <ul className={styles.upcoming_events_list}>
          {upcomingEvents.map((event) => (
            <li>
            <div className={styles.upcoming_events_card}>
              <div className={styles.upcoming_events_card_body}>
                <div className={styles.upcoming_events_card_left_content}>
                  <div className={styles.upcoming_events_card_left_content_month}>
                  <p style={{ textAlign: 'center'}} >{getMonth(new Date(event.EventDate))}</p>
                  </div>
                  <div className={styles.upcoming_events_card_left_content_date}>
                  <p style={{ textAlign: 'center'}}>{new Date(event.EventDate).getDate()}</p>
                  </div>

                </div>
                <div className={styles.upcoming_events_card_right_content}>
                  <p style={{ textAlign: 'left'}}>{event.EventName}</p>
                  <p>{formatDate(new Date(event.EventDate))}</p>
                  {event.TotalBookings - event.TotalSlots === 0 ? (
                    <p>No places left</p>
                  ) : (
                    <p>{`${event.TotalSlots - event.TotalBookings}/${event.TotalSlots}`} places left</p>
                  )}
                </div>
              </div>
              <div className={styles.upcoming_events_card_footer} onClick={()=>navigate('/event_page/' + event.EventID)}>
                <p>View Event</p>
              </div>
            </div>
            <hr/>
          </li>
          ))}
        </ul>
      </div>

    </div>
  )
}

export default UpcomingEvents
