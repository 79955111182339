import axios from 'axios';
import React, { useState, useEffect, useMemo } from 'react';
import styles from './BookingDetails.module.css';
import loginStyles from './Login.module.css';
import registerStyles from './Register.module.css';
import forgotStyles from './ForgotPassword.module.css';
import { FaCalendarDay, FaUsers } from "react-icons/fa";
import { MdGolfCourse } from "react-icons/md";
import { HiUsers } from "react-icons/hi";
import { RiCircleFill } from "react-icons/ri";
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { MdArrowDropDown } from "react-icons/md";
import { FaClock } from "react-icons/fa6";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import { RiStickyNoteAddFill } from "react-icons/ri";
import { API_URL } from '../constant';
import { useNavigate } from 'react-router-dom';
import { IoMdArrowDropdown, IoMdArrowDropup } from "react-icons/io";
import Loader from '../Components/Loader';
import Swal from 'sweetalert2';
import countryList from 'react-select-country-list';
import CustomDropdown from '../Components/CustomDropdown';
import { AiOutlineClose } from "react-icons/ai";
import refreshAccessToken from '../refreshAccessToken';
import { useSelector, useDispatch } from 'react-redux';
import { setAccessToken, setRefreshToken } from '../features/auth/authSlice';

const BookingDetails = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const accessToken = useSelector((state) => state.auth.accessToken);

    const [loading, setLoading] = useState(false);
    const [course, setCourse] = useState(null);
    const [showDates, setShowDates] = useState(true);
    const [showHoles, setShowHoles] = useState(false);
    const [showPlayers, setShowPlayers] = useState(false);
    const [showCalendar, setShowCalendar] = useState(false);
    const [showTime, setShowTime] = useState(false);
    const [showExtras, setShowExtras] = useState(false);
    const [showUser, setShowUser] = useState(false);
    const [price, setPrice] = useState(null);
    const [allHoles, setAllHoles] = useState([]);
    const [selectedHole, setSelectedHole] = useState(null);
    const [hole, setHole] = useState(null);
    const [totalPlayers, setTotalPlayers] = useState(0);
    const [selectedPlayers, setSelectedPlayers] = useState([]);
    const [players, setPlayers] = useState(0);
    const [playersTypes, setPlayerTypes] = useState([]);
    const [playersData, setPlayersData] = useState([]);
    const [joiningAllow, setJoiningAllow] = useState(true);
    const [selectedDate, setSelectedDate] = useState(null);
    const [offDates, setOffDates] = useState([]);
    const [offDays, setOffDays] = useState([]);
    const [eventDates, setEventDates] = useState([]);
    const [timeSheetData, setTimeSheetData] = useState([]);
    const [filteredTimeSheetData, setFilteredTimeSheetData] = useState([]);
    const [selectedTeeTime, setSelectedTeeTime] = useState(null);
    const [expandedSlab, setExpandedSlab] = useState(null);
    const [selectedValue, setSelectedValue] = useState('');
    const [extras, setExtras] = useState([]);
    const [caddy, setCaddy] = useState(false);
    const [cart, setCart] = useState(false);
    const [buggy, setBuggy] = useState(false);
    const [blockToggleShowFromExtras, setBlockToggleShowFromExtras] = useState(false);
    const [blockToggleShowFromPlayers, setBlockToggleShowFromPlayers] = useState(false);
    const [promotion, setPromotion] = useState(null);

    const options = useMemo(() => countryList().getData(), []);

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('')
    const [otp, setOtp] = useState('');
    const [pin, setPin] = useState('');
    const [confirmPin, setConfirmPin] = useState('');

    const [showBooking, setShowBooking] = useState(true);
    const [showLogin, setShowLogin] = useState(false);
    const [showRegister, setShowRegister] = useState(false);
    const [showForgotPassword, setShowForgotPassword] = useState(false);
    const [showPin, setShowPin] = useState(false);

    const getFirstCourse = async () => {
        try {
            setLoading(true);

            const response = await axios.get(API_URL + "/api/getFirstCourse");

            if (response.status === 200) {
                if (response.data.length > 0) {
                    const courseId = response.data[0].CourseID;
                    setCourse(response.data[0]);

                    fethOffDates(courseId);
                    fetchOffDays(courseId);
                    fetchEventDates(courseId);
                }
            }
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    }

    const generateDates = (offDates, offDays, targetCount = 7) => {
        const dates = [];
        const today = new Date();
        let dayOffset = 0; // Tracks the current day offset

        // Get day names
        const dayNames = ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"];

        while (dates.length < targetCount) {
            const date = new Date(today);
            date.setDate(today.getDate() + dayOffset);

            // Adjust for local time zone
            const localDate = new Date(date.getTime() - date.getTimezoneOffset() * 60000);
            const formattedDate = localDate.toISOString().split("T")[0]; // Format as "YYYY-MM-DD"

            // Check if the date is valid
            const dayName = dayNames[localDate.getDay()];
            if (!offDates.includes(formattedDate) && !offDays.includes(dayName) && !eventDates.includes(formattedDate)) {
                dates.push(localDate);
            }

            dayOffset++; // Move to the next day
        }

        return dates;
    };

    const formatDate = (date) => {
        const options = { weekday: 'long', day: 'numeric', month: 'long' };
        return date.toLocaleDateString('en-US', options);
    };

    const availableDates = generateDates(offDates, offDays);

    const fethOffDates = async (courseId) => {
        const response = await axios.get(API_URL + "/api/getAllDateOffs/" + courseId);
        setOffDates(response.data); // Example: ["2024-12-09","2024-12-11"]
    }

    const fetchOffDays = async (courseId) => {
        const response = await axios.get(API_URL + "/api/getAllDayOffs/" + courseId);
        setOffDays(response.data); // Example: ["SUN","SAT"]
    }

    const fetchEventDates = async (courseId) => {
        try {
            const response = await axios.get(API_URL + "/api/getAllEventsDate/" + courseId);

            // Extract only the EventDate values from the response data
            const eventDates = response.data.map(event => event.EventDate);

            setEventDates(eventDates); // Example: ["2025-01-20"]
        } catch (error) {
            console.error("Error fetching event dates:", error);
        }
    };

    useEffect(() => {
        getFirstCourse();
    }, []);

    const isDisabledDate = (date) => {
        const localDate = new Date(date.getTime() - date.getTimezoneOffset() * 60000);
        const formattedDate = localDate.toISOString().split("T")[0];

        if (offDates.includes(formattedDate)) {
            return true;
        }

        const dayNames = ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"];
        const dayName = dayNames[date.getDay()];
        if (offDays.includes(dayName)) {
            return true;
        }

        if (eventDates.includes(formattedDate)) {
            return true;
        }

        return false;
    };

    const filterTimeSheetData = (timeSheetData, selectedDate) => {
        const currentDate = new Date(); // Current date and time
        const formattedDate = currentDate.toISOString().split("T")[0]; // Format as YYYY-MM-DD
        const isToday = formattedDate === selectedDate; // Check if the selected date is today

        const filteredData = (timeSheetData || [])
            .map((item) => {
                // Filter TeeTimes directly within each item
                const filteredTeeTimes = (item.TeeTimes || []).filter((teeTime) => {
                    if (isToday) {
                        const [hour, minute] = teeTime.StartTeeTime.split(":").map(Number);
                        const teeStartTime = new Date(currentDate);
                        teeStartTime.setHours(hour, minute, 0, 0); // Set time for tee start

                        const differenceInMinutes = (teeStartTime - currentDate) / (60 * 1000); // Difference in minutes
                        return differenceInMinutes >= 30; // Include tee times starting in 30+ minutes
                    }
                    return true; // Include all tee times if not today
                });

                return {
                    ...item,
                    TeeTimes: filteredTeeTimes,
                };
            })
            .filter((item) => item.TeeTimes.length > 0); // Remove items with no valid tee times

        // Handle the case where no valid tee times exist
        if (filteredData.length > 0) {
            setExpandedSlab(filteredData[0]?.SlabID); // Assuming SlabID exists
        } else {
            setExpandedSlab(null); // Reset expanded slab if no data exists
        }

        return filteredData;
    };

    const handleShowJoin = (players, filledSlot, totalSlot) => {
        const remainingSlots = totalSlot - filledSlot;

        const isOpenForJoin = filledSlot < 3;

        const canPlayersJoin = players <= remainingSlots;

        if (isOpenForJoin && canPlayersJoin) {
            return true;
        }
        return false;
    };

    const handleDateSelect = async (date) => {
        try {
            setLoading(true);
            const response = await axios.get(`${API_URL}/api/getHolesByDate/1?Date=${formatDateForApi(date)}`);

            setAllHoles(response.data);
            setSelectedHole(response.data[0]);
            setHole(response.data[0]);

            const response2 = await axios.get(`${API_URL}/api/getPlayersByCourse/1}`);
            setTotalPlayers(response2.data.HighestGroupingSlot);

            const response3 = await axios.get(`${API_URL}/api/getTypeOfPlayers`);
            setPlayerTypes(response3.data);
        } catch (error) {
            console.log(error);
        } finally {
            setPlayers(0);
            setSelectedTeeTime(null);
            setSelectedDate(date);
            // getTimeSheetData(date);
            handleShowDates();
            setShowPlayers(true);
            setLoading(false);
        }
    };

    const handleHoleSelect = async () => {
        try {
            setLoading(true);
            const response = await axios.get(`${API_URL}/api/getPlayersByCourse/1}`);
            setTotalPlayers(response.data.HighestGroupingSlot);
        } catch (error) {
            console.log(error);
        } finally {
            setShowHoles(!showHoles);
            setHole(selectedHole);
            setFilteredTimeSheetData(filterTimeSheetData(timeSheetData, selectedHole, formatDateForApi(selectedDate)));
            setShowPlayers(true);
            setPlayers(0);
            setSelectedTeeTime(null);
            setLoading(false);
        }
    };

    const refuseToSelectHole = () => {
        Swal.fire({
            icon: "error",
            title: "Error",
            text: "This hole type is not available on this date",
        });
    };

    const handleSelectPlayers = async () => {
        try {
            setLoading(true);
            const response = await axios.get(`${API_URL}/api/getExtrasByCourse/1`);
            setExtras(response.data);

            const response2 = await axios.post(API_URL + "/api/getTimeSheetByFilter", {
                CourseID: 1,
                Date: formatDateForApi(selectedDate),
                HoleMappingID: hole.HoleMappingID,
                PlayersData: selectedPlayers,
                JoiningAllow: joiningAllow ? 1 : 0,
            });
            setTimeSheetData(response2.data);
            setExpandedSlab(response2.data[0]?.SlabID);
        } catch (error) {
            console.log(error);
        } finally {
            setBlockToggleShowFromPlayers(false);
            setShowPlayers(!showPlayers);
            setPlayers(selectedPlayers.length);
            setPlayersData(selectedPlayers);
            handleShowPlayers();
            setShowExtras(true);
            setSelectedTeeTime(null);
            setLoading(false);
        }
    }

    const handleExtrasSelect = async () => {
        try {
            setLoading(true);
            const response = await axios.post(API_URL + "/api/getTimeSheetByFilter", {
                CourseID: 1,
                Date: formatDateForApi(selectedDate),
                HoleMappingID: hole.HoleMappingID,
                PlayersData: playersData,
                JoiningAllow: joiningAllow ? 1 : 0,
            });
            setTimeSheetData(response.data);
            setExpandedSlab(response.data[0]?.SlabID);
        } catch (error) {
            console.log(error);
        } finally {
            setBlockToggleShowFromExtras(false);
            setShowExtras(!showExtras);
            setShowTime(true);
            setLoading(false);
        }
    }

    const handleTimeClick = (tee) => {
        setSelectedTeeTime(tee);
        setShowTime(false);
        setShowUser(true);
        setPrice(tee.Price);
        if (tee.PromotionID) {
            setPromotion(tee.PromotionID);
            setPrice(tee.PromotionPrice);
        } else {
            setPrice(tee.Price);
            setPromotion(null);
        }
    };

    const handleShowDates = () => {
        setBlockToggleShowFromExtras(false);
        setBlockToggleShowFromPlayers(false);
        setBuggy(0);
        setCart(0);
        setCaddy(0);

        if (showDates === true) {
            setShowDates(false);
        } else {
            setShowDates(true);
            setShowHoles(false);
            setShowPlayers(false);
            setShowExtras(false);
            setShowTime(false);
        }
    }

    const handleShowHoles = () => {
        setBlockToggleShowFromExtras(false);
        setBlockToggleShowFromPlayers(false);
        setBuggy(0);
        setCart(0);
        setCaddy(0);

        if (selectedDate === null) {
            Swal.fire({
                icon: "error",
                title: "Error",
                text: "Please select a date",
            });
            setShowDates(true);
            setShowHoles(false);
            setShowPlayers(false);
            setShowExtras(false);
            setShowTime(false);
            return;
        }

        if (hole === null) {
            Swal.fire({
                icon: "error",
                title: "Error",
                text: "Please select a hole",
            });
            setShowDates(false);
            setShowHoles(true);
            setShowPlayers(false);
            setShowExtras(false);
            setShowTime(false);
            return;
        }

        if (showHoles === true) {
            setSelectedHole(hole);
            setShowHoles(false);
        } else {
            setShowDates(false);
            setShowHoles(true);
            setShowPlayers(false);
            setShowExtras(false);
            setShowTime(false);
        }
    }

    const handleShowPlayers = () => {
        setBlockToggleShowFromExtras(false);
        setBuggy(0);
        setCart(0);
        setCaddy(0);

        if (blockToggleShowFromPlayers === true) {
            return;
        }

        if (selectedDate === null) {
            Swal.fire({
                icon: "error",
                title: "Error",
                text: "Please select a date",
            });
            setShowDates(true);
            setShowHoles(false);
            setShowPlayers(false);
            setShowExtras(false);
            setShowTime(false);
            return;
        }

        if (hole === null) {
            Swal.fire({
                icon: "error",
                title: "Error",
                text: "Please select a hole",
            });
            setShowDates(true);
            setShowHoles(false);
            setShowPlayers(false);
            setShowExtras(false);
            setShowTime(false);
            return;
        }

        if (showPlayers === true) {
            setShowPlayers(false);
        } else {
            setShowDates(false);
            setShowPlayers(true);
            setShowHoles(false);
            setShowExtras(false);
            setShowTime(false);
        }
    }

    const handleShowExtras = () => {
        if (blockToggleShowFromPlayers) {
            return;
        }
        if (blockToggleShowFromExtras) {
            return;
        }
        if (players === 0) {
            Swal.fire({
                icon: "error",
                title: "Error",
                text: "Please select players",
            });
            setShowDates(false);
            setShowHoles(false);
            setShowPlayers(true);
            setShowExtras(false);
            setShowTime(false);
            return;
        }
        if (showExtras === true) {
            setShowExtras(false);
        } else {
            setShowDates(false);
            setShowHoles(false);
            setShowPlayers(false);
            setShowExtras(true);
            setShowTime(false);
        }
    }

    const handleShowTime = () => {
        if (blockToggleShowFromPlayers) {
            return;
        }
        if (blockToggleShowFromExtras) {
            return;
        }
        if (selectedDate === null) {
            Swal.fire({
                icon: "error",
                title: "Error",
                text: "Please select a date",
            });
            setShowDates(true);
            setShowHoles(false);
            setShowPlayers(false);
            setShowExtras(false);
            setShowTime(false);
            return;
        }

        if (hole === null) {
            Swal.fire({
                icon: "error",
                title: "Error",
                text: "Please select a hole",
            });
            setShowDates(false);
            setShowHoles(true);
            setShowPlayers(false);
            setShowExtras(false);
            setShowTime(false);
            return;
        }

        if (players === 0) {
            Swal.fire({
                icon: "error",
                title: "Error",
                text: "Please select players",
            });
            setShowDates(false);
            setShowHoles(false);
            setShowPlayers(true);
            setShowExtras(false);
            setShowTime(false);
            return;
        }

        if (showTime === true) {
            setShowTime(false);
        } else {
            setShowDates(false);
            setShowTime(true);
            setShowHoles(false);
            setShowExtras(false);
            setShowPlayers(false);
        }
    }

    const handleToggleTeeTimeSlab = (slabID) => {
        if (expandedSlab === slabID) {
            setExpandedSlab(null); // Collapse if already expanded
        } else {
            setExpandedSlab(slabID); // Expand the clicked SlabID
        }
    };

    const formatDateForApi = (dateString) => {
        if (dateString !== null) {
            const date = new Date(dateString);
            const day = String(date.getDate()).padStart(2, '0');
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const year = date.getFullYear();
            return `${year}-${month}-${day}`;
        } else {
            return null;
        }
    }

    const getExtrasText = () => {
        const extras = [];
        if (caddy) extras.push("Caddy");
        if (cart) extras.push("Cart");
        if (buggy) extras.push("Buggy");
    
        return extras.length > 0 ? extras.join(", ") : "Extras";
    };

    const createBody = async () => {
        if (selectedTeeTime === null) {
            setShowTime(true);
            return {
                error: 'Please select a time',
            };
        }

        if (hole === null) {
            setShowHoles(true);
            return {
                error: 'Please select a hole type',
            };
        }

        if (players === null) {
            setShowPlayers(true);
            return {
                error: 'Please select number of players',
            };
        }

        if (selectedDate === null) {
            setShowDates(true);
            return {
                error: 'Please select a date',
            };
        }
        return {
            TeeTimeID: selectedTeeTime.TeeTimeID,
            Date: formatDateForApi(selectedDate),
            TotalGolfers: players,
            HoleMappingID: hole.HoleMappingID,
            BookingPricing: price,
            Cart: cart ? 1 : 0,
            Buggy: buggy ? 1 : 0,
            Caddy: caddy ? 1 : 0,
            JoiningAllow: joiningAllow ? 1 : 0,
            PromotionID: promotion,
        };
    };

    const handlePlayerCategoryChange = (index, value) => {
        setSelectedPlayers((prevPlayers) =>
            prevPlayers.map((player, i) => (i === index ? value : player))
        );
    };

    useEffect(() => {
        setSelectedPlayers((prevPlayers) => {
            const updatedPlayers = Array.from({ length: players }, (_, index) =>
                prevPlayers[index] || 1 // Retain existing value or default to 1
            );
            return updatedPlayers;
        });
    }, [players]);

    const getTimeSheetData = async (date) => {
        try {
            const response = await axios.get(`${API_URL}/api/getTimeSheet/1?Date=${formatDateForApi(date)}`);

            if (response.status === 200) {
                setTimeSheetData(response.data);
                setSelectedHole(response.data[0]);
                setHole(response.data[0]);

                setFilteredTimeSheetData(filterTimeSheetData(response.data, response.data[0], formatDateForApi(date)));
            }
        } catch (error) {
            console.log(error);
        }
    }

    const calulatePrice = (prices, selectedPlayers, price, noOfPlayers) => {
        if (prices.length === 0) {
            return price * noOfPlayers;
        }
        const totalPrice = selectedPlayers.reduce((total, player) => {
            const matchedPrice = prices.find(price => price.CatagoryName === player.category);
            return total + (matchedPrice ? parseFloat(matchedPrice.Price) : 0);
        }, 0);
        return totalPrice;
    }

    const handleBookTeeTime = async () => {
        setShowDates(false);
        setShowHoles(false);
        setShowPlayers(false);
        setShowExtras(false);
        setShowTime(false);

        const body = await createBody();
        if (body.error) {
            Swal.fire({
                icon: "error",
                title: "Error",
                text: body.error,
            });
            return;
        }

        try {
            setLoading(true);

            const response = await axios.post(`${API_URL}/api/createNewBooking`, body, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`
                },
            });

            if (response.status === 201) {
                Swal.fire({
                    icon: "success",
                    title: "Success",
                    text: response.data.message,
                    showConfirmButton: true,
                    confirmButtonText: "OK",
                }).then((result) => {
                    if (result.isConfirmed) {
                        navigate("/bookings");
                    }
                });
            }
        } catch (error) {
            console.log(error);

            if (error.response?.status === 500) {
                Swal.fire({
                    icon: "error",
                    title: "Error",
                    text: error.response.data.message,
                });
            }

            if (error.response?.status === 403) {
                await refreshAccessToken();
            }

            if (error.response?.status === 401) {
                await refreshAccessToken();
            }
        } finally {
            setLoading(false);
        }
    }

    const handleSelectLoginAndContinue = () => {
        setShowDates(false);
        setShowHoles(false);
        setShowPlayers(false);
        setShowExtras(false);
        setShowTime(false);

        const body = createBody();
        if (body.error) {
            Swal.fire({
                icon: "error",
                title: "Error",
                text: body.error,
            });
            return;
        }

        setShowBooking(false);
        setShowLogin(true);
        setShowRegister(false);
    }

    const handleLogin = async () => {
        if (email.trim() === '') {
            Swal.fire({
                icon: "error",
                title: "Error",
                text: "Please enter email",
            })
            return;
        }

        if (pin.trim() === '') {
            Swal.fire({
                icon: "error",
                title: "Error",
                text: "Please enter PIN",
            })
            return;
        }

        if (pin.length !== 4) {
            Swal.fire({
                icon: "error",
                title: "Error",
                text: "Please enter a valid PIN",
            })
            return;
        }

        if (!/^\d{4}$/.test(pin)) {
            Swal.fire({
                icon: "error",
                title: "Error",
                text: "Please enter a valid PIN",
            })
            return;
        }

        if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,10})+$/.test(email)) {
            Swal.fire({
                icon: "error",
                title: "Error",
                text: "Please enter a valid email",
            })
            return;
        }

        try {
            setLoading(true);

            const response = await axios.post(`${API_URL}/api/loginAndBooking`, {
                "Email": email,
                "Pin": pin,
                "TeeTimeID": selectedTeeTime.TeeTimeID,
                "Date": formatDateForApi(selectedDate),
                "TotalGolfers": players,
                "HoleMappingID": hole.HoleMappingID,
                "BookingPricing": price,
                "Cart": cart ? 1 : 0,
                "Buggy": buggy ? 1 : 0,
                "Caddy": caddy ? 1 : 0,
                "JoiningAllow": joiningAllow ? 1 : 0,
                PromotionID: promotion,
            });

            if (response.status === 200) {
                dispatch(setAccessToken(response.data.accessToken));
                dispatch(setRefreshToken(response.data.refreshToken));

                navigate('/bookings');
            }
        } catch (error) {
            console.log(error);

            if (error.response?.status === 400) {
                Swal.fire({
                    icon: "error",
                    title: "Error",
                    text: error.response.data.message,
                });
            }

            if (error.response?.status === 401) {
                Swal.fire({
                    icon: "error",
                    title: "Error",
                    text: error.response.data.message,
                });
            }

            if (error.response?.status === 500) {
                Swal.fire({
                    icon: "error",
                    title: "Error",
                    text: error.response.data.message,
                });
            }
        } finally {
            setLoading(false);
        }
    }

    const handleRegister = async () => {
        if (name.trim() === '') {
            Swal.fire({
                icon: "error",
                title: "Error",
                text: "Please enter your name",
            });
            return;
        }

        if (email.trim() === '') {
            Swal.fire({
                icon: "error",
                title: "Error",
                text: "Please enter your email",
            });
            return;
        }

        if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,10})+$/.test(email)) {
            Swal.fire({
                icon: "error",
                title: "Error",
                text: "Please enter a valid email",
            });
            return;
        }

        if (pin.trim() === '') {
            Swal.fire({
                icon: "error",
                title: "Error",
                text: "Please enter your PIN",
            });
            return;
        }

        if (pin.length !== 4) {
            Swal.fire({
                icon: "error",
                title: "Error",
                text: "Please enter a valid PIN",
            });
            return;
        }

        if (!/^\d{4}$/.test(pin)) {
            Swal.fire({
                icon: "error",
                title: "Error",
                text: "Please enter a valid PIN",
            });
            return;
        }

        if (phoneNumber.trim() === '') {
            Swal.fire({
                icon: "error",
                title: "Error",
                text: "Please enter your phone number",
            });
            return;
        }

        if (!/^\d{10}$/.test(phoneNumber)) {
            Swal.fire({
                icon: "error",
                title: "Error",
                text: "Please enter a valid phone number",
            });
            return;
        }

        if (phoneNumber.length !== 10) {
            Swal.fire({
                icon: "error",
                title: "Error",
                text: "Please enter a valid phone number",
            });
            return;
        }

        if (selectedValue.trim() === '') {
            Swal.fire({
                icon: "error",
                title: "Error",
                text: "Please select your gender",
            });
            return;
        }

        try {
            setLoading(true);

            const response = await axios.post(`${API_URL}/api/registerAndBooking`, {
                "Name": name,
                "Email": email,
                "Pin": pin,
                "PhoneNumber": phoneNumber,
                "Country": selectedValue,
                "TeeTimeID": selectedTeeTime.TeeTimeID,
                "Date": formatDateForApi(selectedDate),
                "TotalGolfers": players,
                "HoleMappingID": hole.HoleMappingID,
                "BookingPricing": price,
                "Cart": cart ? 1 : 0,
                "Buggy": buggy ? 1 : 0,
                "Caddy": caddy ? 1 : 0,
                "JoiningAllow": joiningAllow ? 1 : 0,
                PromotionID: promotion,
            });

            if (response.status === 200) {
                Swal.fire({
                    icon: "success",
                    title: "Success",
                    text: "Please check your email to verify your account and complete your booking.",
                    showConfirmButton: true,
                    confirmButtonText: "OK",
                }).then((result) => {
                    if (result.isConfirmed) {
                        window.location.reload();
                    }
                })
            }
        } catch (error) {
            console.log(error);

            if (error.response?.status === 400) {
                Swal.fire({
                    icon: "error",
                    title: "Error",
                    text: error.response.data.message,
                });
            }

            if (error.response?.status === 500) {
                Swal.fire({
                    icon: "error",
                    title: "Error",
                    text: error.response.data.message,
                });
            }
        } finally {
            setLoading(false);
        }
    }

    const goToOtpVerify = async () => {
        if (email.trim() === '') {
            Swal.fire({
                icon: "error",
                title: "Error",
                text: "Please enter your email",
            });
            return;
        }

        if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,10})+$/.test(email)) {
            Swal.fire({
                icon: "error",
                title: "Error",
                text: "Please enter a valid email",
            });
            return;
        }

        try {
            setLoading(true);

            const response = await axios.post(`${API_URL}/api/forgot-password`, { email });

            if (response.status === 200) {
                Swal.fire({
                    icon: "success",
                    title: "Success",
                    text: response.data.message,
                });

                setShowForgotPassword(false);
                setShowPin(true);
            }

        } catch (error) {
            console.log(error);
            Swal.fire({
                icon: "error",
                title: "Error",
                text: error.response.data.message,
            });
        } finally {
            setLoading(false);
        }
    }

    const forgotPassword = async () => {
        if (pin.trim() === '') {
            Swal.fire({
                icon: "error",
                title: "Error",
                text: "Please enter your PIN",
            });
            return;
        }

        if (confirmPin.trim() === '') {
            Swal.fire({
                icon: "error",
                title: "Error",
                text: "Please confirm your PIN",
            });
            return;
        }

        if (pin !== confirmPin) {
            Swal.fire({
                icon: "error",
                title: "Error",
                text: "PINs do not match",
            });
            return;
        }

        if (pin.length !== 4) {
            Swal.fire({
                icon: "error",
                title: "Error",
                text: "Please enter a valid PIN",
            });
            return;
        }

        if (!/^\d{4}$/.test(pin)) {
            Swal.fire({
                icon: "error",
                title: "Error",
                text: "Please enter a valid PIN",
            });
            return;
        }

        if (!/^\d{4}$/.test(confirmPin)) {
            Swal.fire({
                icon: "error",
                title: "Error",
                text: "Please enter a valid PIN",
            });
            return;
        }

        if (otp.trim() === '') {
            Swal.fire({
                icon: "error",
                title: "Error",
                text: "Please enter your OTP",
            });
            return;
        }

        if (otp.length !== 6) {
            Swal.fire({
                icon: "error",
                title: "Error",
                text: "Please enter a valid OTP",
            });
            return;
        }

        if (!/^\d{6}$/.test(otp)) {
            Swal.fire({
                icon: "error",
                title: "Error",
                text: "Please enter a valid OTP",
            });
            return;
        }

        try {
            setLoading(true);

            const response = await axios.post(`${API_URL}/api/reset-password`, { email: email, newPassword: pin, otp: otp });

            if (response.status === 200) {
                Swal.fire({
                    icon: "success",
                    title: "Success",
                    text: "Password reset successfully",
                });

                setShowForgotPassword(false);
                setShowPin(false);
                setShowLogin(true);
                setEmail('');
                setPin('');
                setConfirmPin('');
                setOtp('');
            }

            if (response.status !== 200) {
                Swal.fire({
                    icon: "error",
                    title: "Error",
                    text: response.data.message,
                });
            }
        } catch (error) {
            console.log(error);
            Swal.fire({
                icon: "error",
                title: "Error",
                text: error.response.data.message,
            });
        } finally {
            setLoading(false);
        }
    }

    const handleCloseLogin = () => {
        setShowLogin(false);
        setShowRegister(false);
        setShowBooking(true);
        setShowForgotPassword(false);
    }

    const goToRegister = () => {
        setShowLogin(false);
        setShowRegister(true);
        setShowBooking(false);
        setShowForgotPassword(false);
        setEmail('');
        setPin('');
    }

    const goToLogin = () => {
        setShowLogin(true);
        setShowRegister(false);
        setShowBooking(false);
        setShowForgotPassword(false);
        setEmail('');
        setPin('');
        setName('');
        setPhoneNumber('');
        setSelectedValue('');
    }

    const goToForgotPassword = () => {
        setShowLogin(false);
        setShowRegister(false);
        setShowBooking(false);
        setShowForgotPassword(true);
        setEmail('');
        setPin('');
        setName('');
        setPhoneNumber('');
        setSelectedValue('');
    }

    return (
        <>
            {loading ? (
                <Loader />
            ) : (
                <>
                    {showBooking && (
                        <div className={styles.booking_details_container}>
                            <div className={styles.booking_details_heading}>
                                BOOKING DETAILS
                            </div>


                            <div className={styles.booking_details_full_container}>
                                <div className={styles.date_selection_div}>
                                    <div className={styles.booking_details_section_upper} onClick={handleShowDates}>
                                        <div className={styles.booking_details_section1}>
                                            <div className={styles.booking_details_section1_left}>
                                                <div className={styles.booking_details_section1_left1}>
                                                    <FaCalendarDay />
                                                </div>
                                                {showDates ? (
                                                    <div className={styles.booking_details_section1_left2}>
                                                        Select Date
                                                    </div>
                                                ) : (
                                                    <div className={styles.booking_details_section1_left2}>
                                                        {selectedDate && formatDate(selectedDate) || 'Select Date'}
                                                    </div>
                                                )}
                                            </div>

                                            {showDates ? (
                                                <div className={styles.booking_details_section1_right}>
                                                    <MdArrowDropDown />
                                                </div>
                                            ) : (
                                                <div className={styles.booking_details_section1_right}>
                                                    Edit
                                                </div>
                                            )}
                                        </div>
                                    </div>

                                    {showDates && (
                                        <div className={styles.dates_container2}>
                                            {!showCalendar ? (
                                                <>
                                                    {availableDates.map((date, index) => {
                                                        const today = new Date();
                                                        const tomorrow = new Date(today);
                                                        tomorrow.setDate(today.getDate() + 1);

                                                        // Check if the date is "TODAY" or "TOMORROW"
                                                        const isToday = formatDate(date) === formatDate(today);
                                                        const isTomorrow = formatDate(date) === formatDate(tomorrow);

                                                        return (
                                                            <button key={index} className={styles.date_button2} onClick={() => handleDateSelect(date)}>
                                                                {isToday
                                                                    ? "TODAY"
                                                                    : isTomorrow
                                                                        ? "TOMORROW"
                                                                        : formatDate(date)}
                                                            </button>
                                                        );
                                                    })}
                                                </>
                                            ) : (
                                                <div className={styles.calendar_container2}>
                                                    <Calendar
                                                        onChange={(date) => {
                                                            handleDateSelect(date);  // Close the calendar after selecting a date
                                                        }}
                                                        value={selectedDate}
                                                        tileDisabled={({ date }) => isDisabledDate(date)}
                                                    />
                                                </div>
                                            )}

                                            {showCalendar ? (
                                                <button className={styles.date_button4} onClick={() => setShowCalendar(!showCalendar)}>
                                                    Back to Dates...
                                                </button>
                                            ) : (
                                                <button className={styles.date_button3} onClick={() => setShowCalendar(!showCalendar)}>
                                                    More Dates...
                                                </button>
                                            )}
                                        </div>
                                    )}

                                </div>

                                <div className={styles.booking_details_section_upper} onClick={handleShowHoles}>
                                    <div className={styles.booking_details_section1}>
                                        <div className={styles.booking_details_section1_left}>
                                            <div className={styles.booking_details_section1_left1}>
                                                <MdGolfCourse />
                                            </div>
                                            <div className={styles.booking_details_section1_left2}>
                                                {hole === null ? 'Select Hole' : hole.HoleName + " Holes"}
                                            </div>
                                        </div>

                                        {showHoles ? (
                                            <div className={styles.booking_details_section1_right}>
                                                <MdArrowDropDown />
                                            </div>
                                        ) : (
                                            <div className={styles.booking_details_section1_right}>
                                                Edit
                                            </div>
                                        )}
                                    </div>
                                </div>
                                {showHoles && (
                                    <div className={styles.booking_details_section_upper2}>
                                        <div className={styles.booking_details_section2}>
                                            <div className={styles.booking_details_section1_heading}>
                                                How many holes do you want to play?
                                            </div>
                                            <div className={styles.booking_details_section1_subheading}>
                                                <div className={styles.booking_details_section1_heading_line}></div>
                                            </div>
                                        </div>
                                        <div className={styles.booking_details_section2}>
                                            <div className={styles.hole_buttons_container}>
                                                {allHoles.some(item => item.HoleName === "18") ? (
                                                    <div className={`${selectedHole.HoleName === "18" ? 'hole_button_active' : 'hole_button'}`} onClick={() => setSelectedHole(allHoles[0])}>
                                                        18 Holes
                                                    </div>
                                                ) : (
                                                    <div className={`${selectedHole.HoleName === "18" ? 'hole_button_active' : 'hole_button'}`} onClick={refuseToSelectHole}>
                                                        18 Holes
                                                    </div>
                                                )}
                                                {allHoles.some(item => item.HoleName === "9") ? (
                                                    <div className={`${selectedHole.HoleName === "9" ? 'hole_button_active' : 'hole_button'}`} onClick={() => setSelectedHole(allHoles[1])}>
                                                        9 Holes
                                                    </div>
                                                ) : (
                                                    <div className={`${selectedHole.HoleName === "9" ? 'hole_button_active' : 'hole_button'}`} onClick={refuseToSelectHole}>
                                                        9 Holes
                                                    </div>
                                                )}
                                            </div>
                                            <div className={styles.hole_buttons_container2}>
                                                <div className={styles.hole_button_submit} onClick={handleHoleSelect}>
                                                    Continue
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}

                                <div className={styles.booking_details_section_upper} onClick={handleShowPlayers}>
                                    <div className={styles.booking_details_section1}>
                                        <div className={styles.booking_details_section1_left}>
                                            <div className={styles.booking_details_section1_left1}>
                                                <HiUsers />
                                            </div>
                                            {players === 0 ? (
                                                <div className={styles.booking_details_section1_left2}>
                                                    Select Players
                                                </div>) : (
                                                <div className={styles.booking_details_section1_left2}>
                                                    {players} player{players === 1 ? '' : 's'}
                                                </div>)}
                                            <div className={styles.booking_details_section1_left3}>
                                                {Array.from({ length: players }, (_, index) => (
                                                    <RiCircleFill key={index} />
                                                ))}
                                            </div>
                                        </div>
                                        {showPlayers ? (
                                            <div className={styles.booking_details_section1_right}>
                                                <MdArrowDropDown />
                                            </div>
                                        ) : (
                                            <div className={styles.booking_details_section1_right}>
                                                Edit
                                            </div>
                                        )}
                                    </div>
                                </div>

                                {showPlayers && (
                                    <div className={styles.booking_details_section_upper2}>
                                        <div className={styles.booking_details_section2}>
                                            <div className={styles.booking_details_section1_heading}>
                                                How many golfers are in your party?
                                            </div>
                                            <div className={styles.booking_details_section1_subheading}>
                                                <div className={styles.booking_details_section1_heading_line}></div>
                                            </div>
                                        </div>
                                    </div>
                                )}

                                {showPlayers && (
                                    <div className={styles.booking_details_section_upper2}>
                                        <div className={styles.booking_details_section3}>
                                            <div className={styles.booking_details_section3_inner}>
                                                {Array.from({ length: totalPlayers }, (_, index) => index + 1).map((num) => (
                                                    <div
                                                        key={num}
                                                        className={
                                                            players === num
                                                                ? styles.block_page_container21_active
                                                                : styles.block_page_container21
                                                        }
                                                        onClick={() => { setBlockToggleShowFromPlayers(true); setPlayers(num); }}
                                                    >
                                                        {num}
                                                    </div>
                                                ))}
                                            </div>
                                            {players > 0 && (
                                                <div className={styles.muna_outer}>
                                                    <div className={styles.muna_inner}></div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                )}

                                {selectedPlayers.length > 0 && showPlayers && (
                                    <div className={styles.booking_details_section_upper2}>
                                        <div className={styles.booking_details_section2}>
                                            <div className={styles.booking_details_section1_heading}>
                                                Please select your player category
                                            </div>
                                            <div className={styles.booking_details_section1_subheading}>
                                                <div className={styles.booking_details_section1_heading_line}></div>
                                            </div>
                                        </div>
                                    </div>
                                )}

                                {showPlayers && (
                                    <div className={styles.booking_details_section_upper2}>
                                        <div className={styles.booking_details_section3}>
                                            {Array.from({ length: players }, (_, index) => (
                                                <div className={styles.booking_details_section12} key={index}>
                                                    <select
                                                        className={styles.players_details_container}
                                                        value={selectedPlayers[index] || 1}
                                                        onChange={(e) => { setBlockToggleShowFromPlayers(true); handlePlayerCategoryChange(index, parseInt(e.target.value)); }}
                                                    >
                                                        {playersTypes.map((type) => (
                                                            <option key={type.RateCategoryID} value={type.RateCategoryID}>
                                                                {type.CategoryName}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>
                                            ))}

                                            {players < 3 && (
                                                <div className={styles.checkbox_container} style={{ marginTop: 8 }}>
                                                    <div className={styles.checkbox_group}>
                                                        <input
                                                            className={styles.checkbox_input}
                                                            type="checkbox"
                                                            id="checkbox2"
                                                            checked={joiningAllow ? 1 : 0}
                                                            onChange={() => { setBlockToggleShowFromPlayers(true); setJoiningAllow(!joiningAllow); }}
                                                        />
                                                        <label htmlFor="checkbox2" className={styles.checkbox_label}>Allow others to join</label>
                                                    </div>
                                                </div>
                                            )}

                                            {players > 0 && (
                                                <div
                                                    className={styles.hole_button_submit2}
                                                    onClick={handleSelectPlayers}
                                                >
                                                    Continue
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                )}

                                {/* <div className={styles.booking_details_section_upper' onClick={handleShowExtras}>
                                    <div className={styles.booking_details_section1'>
                                        <div className={styles.booking_details_section1_left'>
                                            <div className={styles.booking_details_section1_left1'>
                                                <RiStickyNoteAddFill />
                                            </div>
                                            <div className={styles.booking_details_section1_left2'>
                                                Extras
                                            </div>
                                        </div>
                                        {showExtras ? (
                                            <div className={styles.booking_details_section1_right'>
                                                <MdArrowDropDown />
                                            </div>
                                        ) : (
                                            <div className={styles.booking_details_section1_right'>
                                                Edit
                                            </div>
                                        )}
                                    </div>
                                </div> */}

                                <div className={styles.booking_details_section_upper} onClick={handleShowExtras}>
                                    <div className={styles.booking_details_section1}>
                                        <div className={styles.booking_details_section1_left}>
                                            <div className={styles.booking_details_section1_left1}>
                                                <RiStickyNoteAddFill />
                                            </div>
                                            <div className={styles.booking_details_section1_left2}>
                                                {getExtrasText()}
                                            </div>
                                        </div>
                                        {showExtras ? (
                                            <div className={styles.booking_details_section1_right}>
                                                <MdArrowDropDown />
                                            </div>
                                        ) : (
                                            <div className={styles.booking_details_section1_right}>
                                                Edit
                                            </div>
                                        )}
                                    </div>
                                </div>

                                {showExtras && (
                                    <div className={styles.booking_details_section_upper2}>
                                        <div className={styles.booking_details_section2}>
                                            <div className={styles.booking_details_section1_heading}>
                                                Please select if you want any extras
                                            </div>
                                            <div className={styles.booking_details_section1_subheading}>
                                                <div className={styles.booking_details_section1_heading_line}></div>
                                            </div>
                                        </div>
                                        <div className={styles.booking_details_section2}>
                                            <div className={styles.booking_extras_container}>
                                                {extras.includes('Cart') && (
                                                    <div className={styles.extra_booking_div}>
                                                        <div className={styles.extra_booking_div_left}>
                                                            <div className={styles.checkbox_container}>
                                                                <div className={styles.checkbox_group}>
                                                                    <input
                                                                        className={styles.checkbox_input}
                                                                        type="checkbox"
                                                                        id="checkbox1"
                                                                        checked={cart}
                                                                        onChange={() => { setBlockToggleShowFromExtras(true); setCart(!cart); }}
                                                                    />
                                                                    <label htmlFor="checkbox1" className={styles.checkbox_label}>Cart</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className={styles.extra_booking_div_right}>
                                                            <div className={styles.extra_price}>
                                                                {/* &#3647; 250 */}
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                                {extras.includes('Buggy') && (
                                                    <div className={styles.extra_booking_div}>
                                                        <div className={styles.extra_booking_div_left}>
                                                            <div className={styles.checkbox_container}>
                                                                <div className={styles.checkbox_group}>
                                                                    <input
                                                                        className={styles.checkbox_input}
                                                                        type="checkbox"
                                                                        id="checkbox2"
                                                                        checked={buggy}
                                                                        onChange={() => { setBlockToggleShowFromExtras(true); setBuggy(!buggy); }}
                                                                    />
                                                                    <label htmlFor="checkbox2" className={styles.checkbox_label}>Buggy</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className={styles.extra_booking_div_right}>
                                                            <div className={styles.extra_price}>
                                                                {/* &#3647; 300 */}
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                                {extras.includes('Caddy') && (
                                                    <div className={styles.extra_booking_div}>
                                                        <div className={styles.extra_booking_div_left}>
                                                            <div className={styles.checkbox_container}>
                                                                <div className={styles.checkbox_group}>
                                                                    <input
                                                                        className={styles.checkbox_input}
                                                                        type="checkbox"
                                                                        id="checkbox3"
                                                                        checked={caddy}
                                                                        onChange={() => { setBlockToggleShowFromExtras(true); setCaddy(!caddy); }}
                                                                    />
                                                                    <label htmlFor="checkbox3" className={styles.checkbox_label}>Caddy</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className={styles.extra_booking_div_right}>
                                                            <div className={styles.extra_price}>
                                                                {/* &#3647; 170 */}
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>

                                            <div className={styles.hole_buttons_container2}>
                                                <div className={styles.hole_button_submit} onClick={handleExtrasSelect}>
                                                    Continue
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}

                                <div className={styles.booking_details_section_upper} onClick={handleShowTime}>
                                    <div className={styles.booking_details_section1}>
                                        <div className={styles.booking_details_section1_left}>
                                            <div className={styles.booking_details_section1_left1}>
                                                <FaClock />
                                            </div>
                                            <div className={styles.booking_details_section1_left2}>
                                                {selectedTeeTime === null ? 'Select Time' : selectedTeeTime.StartTeeTime}
                                            </div>
                                        </div>

                                        {showTime ? (
                                            <div className={styles.booking_details_section1_right}>
                                                <MdArrowDropDown />
                                            </div>
                                        ) : (
                                            <div className={styles.booking_details_section1_right}>
                                                Edit
                                            </div>
                                        )}
                                    </div>
                                </div>

                                {showTime && (
                                    <div className={styles.booking_details_section_upper2}>
                                        {players === 0 ? (
                                            <>
                                                <div className={styles.booking_time_section}>
                                                    <div className={styles.booking_details_section1_heading}>
                                                        <div className={styles.booking_details_section1_heading}>
                                                            Please select how many players
                                                        </div>
                                                        <div className={styles.booking_details_section1_subheading}>
                                                            <div className={styles.booking_details_section1_heading_line}></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        ) : (
                                            <div className={styles.booking_time_section}>
                                                <div className={styles.booking_details_section1_heading}>
                                                    <div className={styles.booking_details_section1_heading}>
                                                        {timeSheetData.length > 0 ? "What time would you like to play?" : "No tee time left"}
                                                    </div>
                                                    <div className={styles.booking_details_section1_subheading}>
                                                        <div className={styles.booking_details_section1_heading_line}></div>
                                                    </div>
                                                </div>

                                                {hole === null ? (
                                                    <>
                                                        <div className={styles.booking_time_div} style={{ marginBottom: '20px', fontSize: '13px' }}>
                                                            Please select hole type first
                                                        </div>
                                                    </>
                                                ) : (
                                                    <div className={styles.booking_time_div}>
                                                        {timeSheetData.map((item) => (
                                                            <>
                                                                <div className={styles.time_div_inner} key={item.SlabID} onClick={() => handleToggleTeeTimeSlab(item.SlabID)}>
                                                                    <>
                                                                        <div className={styles.time_div_inner_left}>
                                                                            <div className={styles.time_div_inner_left_inner_left}>
                                                                                {item.SlabName}
                                                                                <span className={styles.slab_time}>
                                                                                    &nbsp; ({item.StartingTime} - {item.EndingTime})
                                                                                </span>
                                                                            </div>
                                                                            <div className={styles.time_div_inner_left_inner_right}>
                                                                                {expandedSlab === item.SlabID ? <IoMdArrowDropdown /> : <IoMdArrowDropup />}
                                                                            </div>
                                                                        </div>
                                                                    </>
                                                                </div>

                                                                <>
                                                                    {expandedSlab === item.SlabID && item.TeeTimes.map((tee) => (
                                                                        <div className={styles.time_div_inner2} key={tee.TeeTimeID}>
                                                                            <div className={tee.TeeTimeID === selectedTeeTime?.TeeTimeID ? styles.time_div_inner_left2_active : styles.time_div_inner_left2}>
                                                                                <div className={styles.time_div_inner_left2_inner}>
                                                                                    {tee.StartTeeTime}
                                                                                </div>
                                                                            </div>
                                                                            {tee.Restriction === 0 ? (
                                                                                <div className={styles.time_div_inner_right2} onClick={() => handleTimeClick(tee)}>

                                                                                    <div className={styles.with_promotion_left_div}>
                                                                                        <div className={styles.time_div_inner_right2_inner}>
                                                                                            <div className={styles.time_div_inner_right2_inner4}>
                                                                                                <FaUsers />
                                                                                            </div>
                                                                                            <div className={styles.time_div_inner_right2_inner2}>
                                                                                                {item.GroupingSlot - tee.TotalGolfers} Left
                                                                                            </div>
                                                                                        </div>
                                                                                        {tee.PromotionID && (
                                                                                            <div className={styles.promotion_name_timesheet}>
                                                                                                {tee.PromotionName}
                                                                                            </div>
                                                                                        )}
                                                                                    </div>

                                                                                    <div className={styles.time_div_inner_right2_inner}>

                                                                                        {tee.PromotionID ? (
                                                                                            <div className={styles.time_div_inner_right2_inner5}>
                                                                                                <div className={styles.price_without_promotion}>
                                                                                                    &#3647; {tee.Price}
                                                                                                </div>
                                                                                                <div className={styles.price_with_promotion}>
                                                                                                    &#3647; {tee.PromotionPrice}
                                                                                                </div>
                                                                                            </div>
                                                                                        ) : (
                                                                                            <div className={styles.time_div_inner_right2_inner}>
                                                                                                &#3647; {tee.Price}
                                                                                            </div>
                                                                                        )}
                                                                                        <div className={styles.time_div_inner_right2_inner2}>
                                                                                            <MdOutlineKeyboardArrowRight />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            ) : (
                                                                                <div className={styles.time_div_inner_right2_disabled}>

                                                                                    <div className={styles.with_promotion_left_div}>
                                                                                        <div className={styles.time_div_inner_right2_inner}>
                                                                                            <div className={styles.time_div_inner_right2_inner4}>
                                                                                                <FaUsers />
                                                                                            </div>
                                                                                            <div className={styles.time_div_inner_right2_inner2}>
                                                                                                {item.GroupingSlot - tee.TotalGolfers} Left
                                                                                            </div>
                                                                                        </div>
                                                                                        {tee.PromotionID && (
                                                                                            <div className={styles.promotion_name_timesheet}>
                                                                                                {tee.PromotionName}
                                                                                            </div>
                                                                                        )}
                                                                                    </div>

                                                                                    <div className={styles.time_div_inner_right2_inner}>

                                                                                        {tee.PromotionID ? (
                                                                                            <div className={styles.time_div_inner_right2_inner5}>
                                                                                                <div className={styles.price_without_promotion}>
                                                                                                    &#3647; {tee.Price}
                                                                                                </div>
                                                                                                <div className={styles.price_with_promotion}>
                                                                                                    &#3647; {tee.PromotionPrice}
                                                                                                </div>
                                                                                            </div>
                                                                                        ) : (
                                                                                            <div className={styles.time_div_inner_right2_inner}>
                                                                                                &#3647; {tee.Price}
                                                                                            </div>
                                                                                        )}
                                                                                        <div className={styles.time_div_inner_right2_inner2}>
                                                                                            <MdOutlineKeyboardArrowRight />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            )}
                                                                        </div>
                                                                    ))}
                                                                </>
                                                            </>
                                                        ))}
                                                    </div>
                                                )}
                                            </div>
                                        )}
                                    </div>
                                )}

                                {selectedTeeTime ? (
                                    <div className={styles.booking_details_right}>
                                        {accessToken ? (
                                            <button className={styles.booking_details_right_button} onClick={handleBookTeeTime}>Book Tee Time</button>
                                        ) : (
                                            <button className={styles.booking_details_right_button} onClick={handleSelectLoginAndContinue}>Continue With Login</button>
                                        )}
                                    </div>
                                ) : (
                                    <div className={styles.booking_details_right}>
                                        {accessToken ? (
                                            <button className={styles.booking_details_right_button} disabled>Book Tee Time</button>
                                        ) : (
                                            <button className={styles.booking_details_right_button} disabled>Continue With Login</button>
                                        )}
                                    </div>
                                )}
                            </div>
                        </div>
                    )}

                    {showLogin && (
                        <div className={styles.login_container_div_2}>
                            <div className={styles.close_button_container}>
                                <AiOutlineClose onClick={handleCloseLogin} />
                            </div>
                            <div className={loginStyles.login_heading_div}>
                                Login
                            </div>

                            <div className={loginStyles.login_form_container}>
                                <div className={loginStyles.input_group}>
                                    <label className={loginStyles.input_label_login}>Email</label>
                                    <input className={loginStyles.input_field_login} type="text" placeholder='Enter your email' onChange={(e) => setEmail(e.target.value)} value={email} />
                                </div>

                                <div className={loginStyles.input_group}>
                                    <label className={loginStyles.input_label_login}>PIN</label>
                                    <input className={loginStyles.input_field_login} type="password" placeholder='Enter your PIN' onChange={(e) => setPin(e.target.value)} value={pin}
                                        onInput={(e) => { e.target.value = e.target.value.replace(/[^0-9]/g, '').slice(0, 4); }} />
                                </div>

                                <div className={loginStyles.forgot_password_link}>
                                    <span style={{ color: '#007bff', cursor: 'pointer' }} onClick={goToForgotPassword}>Forgot Your PIN?</span>
                                </div>

                                <div className={loginStyles.login_button_container}>
                                    <button className={loginStyles.login_button} onClick={handleLogin}>Login</button>
                                </div>

                                <div className={loginStyles.register_link}>
                                    Don't have an account? <span style={{ color: '#007bff', cursor: 'pointer' }} onClick={goToRegister}>Register here</span>
                                </div>
                            </div>
                        </div>
                    )}

                    {showRegister && (
                        <div className={styles.login_container_div_2}>
                            <div className={styles.close_button_container}>
                                <AiOutlineClose onClick={handleCloseLogin} />
                            </div>
                            <div className={loginStyles.login_heading_div}>
                                Register
                            </div>

                            <div className={registerStyles.register_form_container}>
                                <div className={registerStyles.input_group}>
                                    <label className={registerStyles.input_label_register}>Name</label>
                                    <input className={registerStyles.input_field_register} type="text" placeholder='Full Name' onChange={(e) => setName(e.target.value)} value={name} />
                                </div>

                                <div className={registerStyles.input_group}>
                                    <label className={registerStyles.input_label_register}>Email</label>
                                    <input className={registerStyles.input_field_register} type="text" placeholder='Email Address' onChange={(e) => setEmail(e.target.value)} value={email} />
                                </div>

                                <div className={registerStyles.input_group}>
                                    <label className={registerStyles.input_label_register}>PIN</label>
                                    <input className={registerStyles.input_field_register} type="password" maxLength="4"
                                        pattern="\d{4}"
                                        onInput={(e) => {
                                            e.target.value = e.target.value.replace(/[^0-9]/g, '').slice(0, 4);
                                        }} placeholder='Enter New 4 Digit PIN'
                                        onChange={(e) => setPin(e.target.value)} />
                                </div>

                                <div className={registerStyles.input_group}>
                                    <label className={registerStyles.input_label_register}>Phone Number</label>
                                    <input className={registerStyles.input_field_register} type="text" placeholder='Phone Number'
                                        pattern="[0-9]{10}"
                                        onInput={(e) => {
                                            e.target.value = e.target.value.replace(/[^0-9]/g, '').slice(0, 10);
                                        }}
                                        onChange={(e) => setPhoneNumber(e.target.value)} value={phoneNumber}
                                    />
                                </div>

                                <div className={registerStyles.input_group}>
                                    <label className={registerStyles.input_label_register}>Country</label>
                                    <CustomDropdown
                                        options={options}
                                        value={selectedValue}
                                        onChange={setSelectedValue}
                                    />
                                </div>

                                <div className={registerStyles.register_button_container}>
                                    <button className={registerStyles.register_button} onClick={handleRegister}>Register</button>
                                </div>

                                <div className={loginStyles.login_link}>
                                    Already have an account? <span style={{ color: '#007bff', cursor: 'pointer' }} onClick={goToLogin}>Login here</span>
                                </div>
                            </div>
                        </div>
                    )}

                    {showForgotPassword && (
                        <div className={styles.login_container_div_2}>
                            <div className={styles.close_button_container}>
                                <AiOutlineClose onClick={handleCloseLogin} />
                            </div>
                            <div className={loginStyles.login_heading_div}>
                                Forgot PIN
                            </div>

                            <div className={forgotStyles.forgot_password_form_container}>
                                <div className={forgotStyles.input_group}>
                                    <label className={forgotStyles.input_label_forgot_password}>Email</label>
                                    <input className={forgotStyles.input_field_forgot_password} type="email" placeholder='Enter your email' onChange={(e) => setEmail(e.target.value)} value={email} />
                                </div>

                                <div className={forgotStyles.forgot_password_button_container}>
                                    <button className={forgotStyles.forgot_password_button} onClick={goToOtpVerify}>Send Reset OTP</button>
                                </div>
                            </div>

                            <div className={styles.login_link2}>
                                Already know your PIN? <span style={{ color: '#007bff', cursor: 'pointer' }} onClick={goToLogin}>Login here</span>
                            </div>
                        </div>
                    )}

                    {showPin && (
                        <div className={styles.login_container_div_2}>
                            <div className={styles.close_button_container}>
                                <AiOutlineClose onClick={handleCloseLogin} />
                            </div>
                            <div className={forgotStyles.login_heading_div}>
                                Forgot PIN
                            </div>

                            <div className={forgotStyles.forgot_password_form_container}>
                                <div className={forgotStyles.input_group}>
                                    <label className={forgotStyles.input_label_forgot_password}>OTP</label>
                                    <input className={forgotStyles.input_field_forgot_password} type="text" placeholder='Enter OTP' onChange={(e) => setOtp(e.target.value)} value={otp} onInput={(e) => { e.target.value = e.target.value.replace(/[^0-9]/g, '').slice(0, 6); }} />
                                </div>

                                <div className={forgotStyles.input_group}>
                                    <label className={forgotStyles.input_label_forgot_password}>PIN</label>
                                    <input className={forgotStyles.input_field_forgot_password} type="password" placeholder='Enter PIN' onChange={(e) => setPin(e.target.value)} value={pin} onInput={(e) => { e.target.value = e.target.value.replace(/[^0-9]/g, '').slice(0, 4); }} />
                                </div>

                                <div className={forgotStyles.input_group}>
                                    <label className={forgotStyles.input_label_forgot_password}>Confirm PIN</label>
                                    <input className={forgotStyles.input_field_forgot_password} type="password" placeholder='Confirm PIN' onChange={(e) => setConfirmPin(e.target.value)} value={confirmPin} onInput={(e) => { e.target.value = e.target.value.replace(/[^0-9]/g, '').slice(0, 4); }} />
                                </div>

                                <div className={forgotStyles.forgot_password_button_container}>
                                    <button className={forgotStyles.forgot_password_button} onClick={forgotPassword}>Reset Password</button>
                                </div>
                            </div>

                            <div className={styles.login_link2}>
                                Already know your PIN? <span style={{ color: '#007bff', cursor: 'pointer' }} onClick={goToLogin}>Login here</span>
                            </div>
                        </div>
                    )}
                </>
            )}
        </>
    )
}

export default BookingDetails
