import React from 'react';
import { useDispatch } from 'react-redux';
import { setLanguage } from '../features/language/languageSlice';
import styles from './LanguagePage.module.css';

const LanguagePage = () => {
  const dispatch = useDispatch();

  const handleLanguageSelection = (language) => {
    dispatch(setLanguage(language));
    window.location.reload();
  };

  return (
    <div className={styles.language_container}>
      <div className={styles.img_container}>
        <img src="/Bushwood.png" alt="Bushwood Photo" className={styles.bushwood_img} />
      </div>

      <div className={styles.description_container}>
        Bushwood Golf Club <br />Online Booking
      </div>

      <div className={styles.choose_language_container}>
        <div className={styles.language_box} onClick={() => handleLanguageSelection('th')}>
          ภาษาไทย
        </div>
        <div className={styles.language_box} onClick={() => handleLanguageSelection('en')}>
          English
        </div>
        <div className={styles.language_box} onClick={() => handleLanguageSelection('ko')}>
          한국어
        </div>
      </div>
    </div>
  );
};

export default LanguagePage;
