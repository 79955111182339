import React, { useEffect, useState } from 'react';
import styles from './PromotionMain.module.css';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { API_URL, reverseDateString } from '../constant';
import Loader from '../Components/Loader';

const promotions = [
  {
    id: 1,
    image: 'BGCOffer2.jpg',
    title: 'Weekend Golf Special',
    startDate: '2024-12-01',
    endDate: '2024-12-31',
  },
  {
    id: 2,
    image: 'BGCOffer1.jpg',
    title: 'Holiday Discount Package',
    startDate: '2024-12-15',
    endDate: '2025-01-15',
  },
  {
    id: 3,
    image: 'BGCOffer3.jpg',
    title: 'Twilight Tee Times',
    startDate: '2024-12-10',
    endDate: '2024-12-20',
  },
  {
    id: 4,
    image: 'BGCOffer4.jpg',
    title: 'Manifest Tee Times',
    startDate: '2024-12-13',
    endDate: '2024-12-20',
  },
];

const PromotionMain = () => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [promotionData, setPromotionData] = useState([]);

  const getPromotionList = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`${API_URL}/api/getAllPromotions/1`);
      setPromotionData(response.data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    getPromotionList();
  }, []);

  return (
    <>
      {loading ? <Loader /> : (
        <div className={styles.promotion_container}>
          <div className={styles.promotion_heading}>
            THIS MONTHS PROMOTIONS
          </div>

          <div className={styles.promotion_container_inner}>
            {promotionData.map((promo) => (
              <div key={promo.PromotionID} className={styles.promotion_card}>

                <div className={styles.promotion_header} onClick={() => navigate('/promotion_details/' + promo.PromotionID)}>
                  <div className={styles.promotion_title}>{promo.PromotionName}</div>
                  <div className={styles.promotion_time}>{reverseDateString(promo.StartDate)} - {reverseDateString(promo.EndDate)}</div>
                </div>

                <img src={API_URL + promo.Photo} alt={promo.PromotionName} className={styles.promotion_image} onClick={() => navigate('/promotion_details/' + promo.PromotionID)} />

                <div
                  className={styles.promotion_footer}
                  onClick={() => navigate('/book-promotion/' + promo.PromotionID)}
                >
                  <span className={styles.promotion_footer_text}>Book Tee Time</span>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  )
}

export default PromotionMain
