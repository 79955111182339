import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { API_URL } from '../../constant';

// Async thunk to refresh the accessToken
export const refreshAccessToken = createAsyncThunk(
  'auth/refreshAccessToken',
  async (_, { getState, dispatch, rejectWithValue }) => {
    const { refreshToken } = getState().auth;
    if (!refreshToken) {
      return rejectWithValue('No refresh token available');
    }
    try {
      const response = await axios.post(`${API_URL}/api/refresh-accessToken`, {
        refreshToken,
      });
      const { accessToken } = response.data;
      dispatch(setAccessToken(accessToken)); // Update the access token in the state
      return accessToken;
    } catch (error) {
      if (error.response && error.response.status === 403) {
        dispatch(clearAccessToken()); // Clear the access token if refresh fails
        dispatch(clearRefreshToken()); // Clear the refresh token if refresh fails
      }
      return rejectWithValue(error.response?.data || 'Failed to refresh access token');
    }
  }
);

// Async thunk to check if accessToken is expired
export const checkAccessToken = createAsyncThunk(
  'auth/checkAccessToken',
  async (_, { getState, dispatch, rejectWithValue }) => {
    const { accessToken } = getState().auth;
    if (!accessToken) {
      return rejectWithValue('No access token available');
    }
    try {
      const response = await axios.get(`${API_URL}/api/getAPIByAccessToken`, {
        headers: { Authorization: `Bearer ${accessToken}` },
      });
      return response.data; // Assume the API returns whether the token is valid
    } catch (error) {
      // Call refreshAccessToken if the accessToken check fails
      const refreshResult = await dispatch(refreshAccessToken());
      if (refreshAccessToken.rejected.match(refreshResult)) {
        return rejectWithValue(refreshResult.payload || 'Access token check and refresh failed');
      }
      // Retry checkAccessToken after refreshing
      try {
        const retryResponse = await axios.get(`${API_URL}/api/getAPIByAccessToken`, {
          headers: { Authorization: `Bearer ${refreshResult.payload}` },
        });
        return retryResponse.data;
      } catch (retryError) {
        return rejectWithValue(retryError.response?.data || 'Access token check failed after refresh');
      }
    }
  }
);

const initialState = {
  accessToken: localStorage.getItem('accessToken') || null,
  refreshToken: localStorage.getItem('refreshToken') || null,
  isLoading: false,
  error: null,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setAccessToken: (state, action) => {
      state.accessToken = action.payload;
      localStorage.setItem('accessToken', action.payload);
    },
    clearAccessToken: (state) => {
      state.accessToken = null;
      localStorage.removeItem('accessToken');
    },
    setRefreshToken: (state, action) => {
      state.refreshToken = action.payload;
      localStorage.setItem('refreshToken', action.payload);
    },
    clearRefreshToken: (state) => {
      state.refreshToken = null;
      localStorage.removeItem('refreshToken');
    },
  },
  extraReducers: (builder) => {
    builder
      // Check access token
      .addCase(checkAccessToken.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(checkAccessToken.fulfilled, (state) => {
        state.isLoading = false;
        state.error = null;
      })
      .addCase(checkAccessToken.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload || 'Access token check failed';
      })
      // Refresh access token
      .addCase(refreshAccessToken.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(refreshAccessToken.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = null;
        state.accessToken = action.payload;
      })
      .addCase(refreshAccessToken.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload || 'Failed to refresh access token';
      });
  },
});

export const {
  setAccessToken,
  clearAccessToken,
  setRefreshToken,
  clearRefreshToken,
} = authSlice.actions;

export default authSlice.reducer;
