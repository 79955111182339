import React, { useState, useEffect, useMemo } from 'react';
import styles from './EventBookingDone.module.css';
import Loader from '../Components/Loader';
import axios from 'axios';
import { API_URL } from '../constant';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { checkAccessToken } from '../features/auth/authSlice';
import Swal from 'sweetalert2';
import countryList from 'react-select-country-list';
import CustomDropdown from '../Components/CustomDropdown';

const EventBookingDone = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { id } = useParams();

    const options = useMemo(() => countryList().getData(), []);

    const accessToken = useSelector((state) => state.auth.accessToken);

    const [loading, setLoading] = useState(false);
    const [eventDetails, setEventDetails] = useState({});
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [playerDetails, setPlayerDetails] = useState([]);
    const [currentPlayerIndex, setCurrentPlayerIndex] = useState(0);

    const fetchEventDetails = async (id) => {
        try {
            setLoading(true);
            const response = await axios.get(`${API_URL}/api/getEventById/${id}`);
            setEventDetails(response.data);
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    };

    const getPlayerDetails = async () => {
        try {
            setLoading(true);
            const response = await axios.get(`${API_URL}/api/getEventBookingByEventID/${id}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`
                }
            });
            if (response.status === 200) {
                const response2 = await axios.get(`${API_URL}/api/getEventBookingDetails/${response.data.EventBookingID}`, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${accessToken}`
                    }
                });
                if (response2.status === 200) {
                    setPlayerDetails(response2.data.eventBookingDetails);
                }
            }
        } catch (error) {
            console.log(error);
            if (error.response?.status !== 200) {
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: 'Something went wrong',
                });
            }
        } finally {
            setLoading(false);
        }
    };

    const formatDate = (date) => {
        const options = { weekday: 'long', day: 'numeric', month: 'long', year: 'numeric' };
        return date.toLocaleDateString('en-US', options);
    };

    const openPlayerModal = (index) => {
        setCurrentPlayerIndex(index);
        setIsModalOpen(true);
    };

    useEffect(() => {
        fetchEventDetails(id);
        dispatch(checkAccessToken());
    }, [id]);

    useEffect(() => {
        getPlayerDetails();
    }, []);

    return (
        <>
            {loading ? <Loader /> : (
                <div className={styles.event_booking_done_container}>
                    <div className={styles.event_booking_done_heading}>
                        <p>{eventDetails.EventName}</p>
                        <p style={{ fontSize: '17px' }}>{formatDate(new Date(eventDetails.EventDate))}</p>
                    </div>

                    <div className={styles.event_booking_done_content}>
                        {[...Array(eventDetails.TeamSize || 0)].map((_, index) => {
                            const isPlayerComplete = playerDetails[index] &&
                                playerDetails[index].EUserName &&
                                playerDetails[index].EUserEmail &&
                                playerDetails[index].EUserPhone &&
                                playerDetails[index].EUserCountry;

                            return (
                                <button
                                    key={index}
                                    className={styles.players_details_button}
                                    onClick={() => openPlayerModal(index)}
                                >
                                    Player {index + 1} Details
                                </button>
                            );
                        })}
                    </div>

                    {isModalOpen && (
                        <div className={styles.modal_overlay}>
                            <div className={styles.modal_container}>
                                <div className={styles.modal_header}>
                                    <h2>Player {currentPlayerIndex + 1} Details</h2>
                                    <button className={styles.modal_close} onClick={() => setIsModalOpen(false)}>&times;</button>
                                </div>
                                <div className={styles.modal_content}>
                                    {playerDetails[currentPlayerIndex] ? (
                                        <>
                                        <div className={styles.player_details_container}>
                                            <div className={styles.player_detail}>
                                                <label>Name</label>
                                                <span>{playerDetails[currentPlayerIndex].EUserName || 'N/A'}</span>
                                            </div>
                                            <div className={styles.player_detail}>
                                                <label>Email</label>
                                                <span>{playerDetails[currentPlayerIndex].EUserEmail || 'N/A'}</span>
                                            </div>
                                            <div className={styles.player_detail}>
                                                <label>Phone</label>
                                                <span>{playerDetails[currentPlayerIndex].EUserPhone || 'N/A'}</span>
                                            </div>
                                            <div className={styles.player_detail}>
                                                <label>Country</label>
                                                <span>{playerDetails[currentPlayerIndex].EUserCountry || 'N/A'}</span>
                                            </div>
                                        </div>
                                    </>
                                    ) : (
                                        <p>No details available for this player.</p>
                                    )}
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            )}
        </>
    );
};

export default EventBookingDone;
