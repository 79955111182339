import React, { useState } from 'react';
import styles from './Login.module.css';
import { FcGoogle } from "react-icons/fc";
import Loader from '../Components/Loader';
import axios from 'axios';
import { API_URL } from '../constant';
import Swal from 'sweetalert2';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setAccessToken, setRefreshToken } from '../features/auth/authSlice';

const Login = () => {
  const location = useLocation();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [pin, setPin] = useState('');

  const handleLogin = async () => {
    if (email.trim() === '') {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Please enter email",
      })
      return;
    }

    if (pin.trim() === '') {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Please enter password",
      })
      return;
    }

    if (pin.length !== 4) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Please enter a valid password",
      })
      return;
    }

    if (!/^\d{4}$/.test(pin)) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Please enter a valid password",
      })
      return;
    }

    if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,10})+$/.test(email)) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Please enter a valid email",
      })
      return;
    }

    setLoading(true);
    try {
      const response = await axios.post(`${API_URL}/api/userLogin`, {
        email: email,
        password: pin
      });

      if (response.status === 200) {
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "Login successful",
        });
        dispatch(setAccessToken(response.data.accessToken));
        dispatch(setRefreshToken(response.data.refreshToken));
      }

      if (response.status !== 200) {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: response.data.message,
        })
      }
    } catch (error) {
      console.log(error);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: error.response.data.message,
      })
    } finally {
      setLoading(false);
    }
  }

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div className={styles.login_container_div}>
          <div className={styles.login_heading_div}>
            Login
          </div>

          {location.pathname !== '/login' && (
            <div className={styles.login_session_expired}>
              Session Expired. Please login again.
            </div>
          )}

          <div className={styles.login_form_container}>
            <div className={styles.input_group}>
              <label className={styles.input_label_login}>Email</label>
              <input className={styles.input_field_login} type="text" placeholder='Enter your email' onChange={(e) => setEmail(e.target.value)} value={email} />
            </div>

            <div className={styles.input_group}>
              <label className={styles.input_label_login}>PIN</label>
              <input className={styles.input_field_login} type="password" placeholder='Enter your PIN' onChange={(e) => setPin(e.target.value)} value={pin}
                onInput={(e) => { e.target.value = e.target.value.replace(/[^0-9]/g, '').slice(0, 4); }} />
            </div>

            <div className={styles.forgot_password_link}>
              <a href='/forgot-password'>Forgot Your PIN?</a>
            </div>

            <div className={styles.login_button_container}>
              <button className={styles.login_button} onClick={handleLogin}>Login</button>
            </div>

            <div className={styles.register_link}>
              Don't have an account? <a href='/register' style={{ textDecoration: 'none' , color: 'blue', cursor: 'pointer', fontWeight: '600' }}>Register here</a>
            </div>
          </div>
        </div>
      )
      }
    </>
  );
};

export default Login;