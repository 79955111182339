import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styles from './App.module.css';
import styles2 from './Pages/LanguagePage.module.css';
import { Routes, Route } from 'react-router-dom';
import LanguagePage from './Pages/LanguagePage';
import HomePage from './Pages/HomePage';
import Header from './Components/Header';
import Footer from './Components/Footer';
import { AiOutlineMenu } from "react-icons/ai";
import { RiMenu3Line } from "react-icons/ri";
import { FaArrowLeftLong } from "react-icons/fa6";
import { useNavigate } from 'react-router-dom';
import UpcomingEvents from './Pages/UpcomingEvents';
import EventPage from './Pages/EventPage';
import EventDetails from './Pages/EventDetails';
import Rates from './Pages/Rates';
import PromotionMain from './Pages/PromotionMain';
import PromotionDetails from './Pages/PromotionDetails';
import BookTeeTime from './Pages/BookTeeTime';
import CourseInformation from './Pages/CourseInformation';
import CourseHandicap from './Pages/CourseHandicap';
import PrivacyPolicy from './Pages/PrivacyPolicy';
import TermsOfService from './Pages/TermsOfService';
import BookingDetails from './Pages/BookingDetails';
import TimeSheet from './Pages/TimeSheet';
import Login from './Pages/Login';
import Register from './Pages/Register';
import VerifyOTP from './Pages/VerifyOTP';
import ForgotPassword from './Pages/ForgotPassword';
import Bookings from './Pages/Bookings';
import ActivateAccount from './Pages/ActivateAccount';
import ActivateBookingAccount from './Pages/ActivateBookingAccount';
import { setAccessToken, clearAccessToken, clearRefreshToken } from './features/auth/authSlice';
import { setLanguage, clearLanguage } from './features/language/languageSlice';
import BookPromotion from './Pages/BookPromotion';
import { API_URL } from './constant';
import EventBooking from './Pages/EventBooking';
import EventBookingDone from './Pages/EventBookingDone';

function App() {
  const language = useSelector((state) => state.language.language);
  const accessToken = useSelector((state) => state.auth.accessToken);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleMenuToggle = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleLanguageChange = (newLanguage) => {
    dispatch(setLanguage(newLanguage));
    window.location.reload();
  };

  const handleClearLanguage = () => {
    dispatch(clearLanguage());
    window.location.reload();
  };

  const handleBack = () => {
    navigate(-1);
  };

  // Example function to handle access token update
  const updateAccessToken = (newToken) => {
    dispatch(setAccessToken(newToken));
  };

  // Example function to clear access token
  const logout = () => {
    dispatch(clearAccessToken());
    navigate('/login');
  };

  return (
    <div className={styles.App}>
      {!language ? <LanguagePage /> : (
        <>
          {isMenuOpen ? (
            <div className={styles2.layout_menu_wrapper}>
              <div className={styles2.header_container}>
                <div className={styles2.header_container_inner}>
                  <div className={styles2.img_container}>
                    <img src="/Bushwood.png" alt="Bushwood Photo" className={styles2.bushwood_img_header} />
                  </div>

                  <div className={styles2.humburger_container}>
                    <RiMenu3Line onClick={handleMenuToggle} />
                  </div>
                </div>
              </div>

              <div className={styles2.menu_item_container}>
                <ul className={styles2.menu_item_list}>
                  <li className={styles2.manu_Item_li_bold} onClick={handleMenuToggle} onClickCapture={() => navigate('/')}>Back to Dashboard</li>
                  <li className={styles2.manu_Item_li} onClick={handleMenuToggle} onClickCapture={handleClearLanguage}>Change Language</li>
                  <li className={styles2.manu_Item_li} onClick={handleMenuToggle} onClickCapture={() => window.open('https://maps.app.goo.gl/AHnYWidwEKnU2m9K8', '_blank')}>Get Directions</li>
                  <li className={styles2.manu_Item_li} onClick={handleMenuToggle} onClickCapture={() => navigate('/course_information')}>Course Information</li>
                  <li className={styles2.manu_Item_li} onClick={handleMenuToggle} onClickCapture={() => navigate('/course_handicap')}>Course Handicap</li>
                  <li className={styles2.manu_Item_li} onClick={handleMenuToggle} onClickCapture={() => window.open('https://www.thaicountryclub.com/', '_blank')}>Course Website</li>
                  <li className={styles2.manu_Item_li} onClick={handleMenuToggle} onClickCapture={() => navigate('/privacy_policy')}>Privacy Policy</li>
                  <li className={styles2.manu_Item_li} onClick={handleMenuToggle} onClickCapture={() => navigate('/terms_of_service')}>Terms of Service</li>
                  {accessToken && (
                    <li
                    className={styles2.logout_link}
                    onClickCapture={() => {
                      dispatch(clearAccessToken()); // Dispatch the action to clear the access token
                      dispatch(clearRefreshToken());
                      handleMenuToggle(); // Invoke the menu toggle function
                      navigate('/login');
                    }}
                  >
                    Logout
                  </li>
                    )}
                </ul>
              </div>

              <div className={styles2.language_footer_div}>
                <a href="https://www.lotusagency.asia/" className={styles2.language_footer_link}>Powered by Tee Time Pro</a>
              </div>
            </div>
          ) : (
            <div className={styles.layout_wrapper}>
              <>
                <div className={styles2.header_container}>
                  <div className={styles2.header_container_inner}>
                    <div className={styles2.img_container}>
                      <img src="/Bushwood.png" alt="Bushwood Photo" className={styles2.bushwood_img_header} />
                    </div>

                    <div className={styles2.login_container}>
                      {localStorage.getItem('accessToken') ? (
                        ""
                      ) : (
                        <div className={styles2.login_box} onClick={() => navigate('/login')}>
                          Login
                        </div>
                      )}
                      <div className={styles2.humburger_container}>
                        <AiOutlineMenu onClick={handleMenuToggle} />
                      </div>
                    </div>
                  </div>

                  <div className={styles2.back_button_container}>
                    <FaArrowLeftLong onClick={handleBack} /> &nbsp; &nbsp;
                    <p className={styles2.back_button_text} onClick={handleBack}>Back</p>
                  </div>
                </div>
              </>
              {/* Routing starts here */}
              <Routes>
                <Route path="/" element={<HomePage />} />
                <Route path="/upcoming_events" element={<UpcomingEvents />} />
                <Route path="/event_page/:id" element={<EventPage />} />
                <Route path="/event_details/:id" element={<EventDetails />} />
                <Route path="/event_booking/:id" element={accessToken ? <EventBooking /> : <Login />} />
                <Route path="/event_booking_done/:id" element={accessToken ? <EventBookingDone /> : <Login />} />
                <Route path='/view_rates' element={<Rates />} />
                <Route path='/promotions' element={<PromotionMain />} />
                <Route path='/promotion_details/:id' element={<PromotionDetails />} />
                <Route path='/book_tee_time' element={<BookTeeTime />} />
                <Route path='/course_information' element={<CourseInformation />} />
                <Route path='/course_handicap' element={<CourseHandicap />} />
                <Route path='/privacy_policy' element={<PrivacyPolicy />} />
                <Route path='/terms_of_service' element={<TermsOfService />} />
                <Route path='/booking_details' element={<BookingDetails />} />
                <Route path='/timesheet' element={<TimeSheet />} />
                <Route path='/login' element={accessToken ? <HomePage /> : <Login />} />
                <Route path='/register' element={accessToken ? <HomePage /> : <Register />} />
                <Route path='/verify-otp' element={accessToken ? <HomePage /> : <VerifyOTP />} />
                <Route path='/forgot-password' element={accessToken ? <HomePage /> : <ForgotPassword />} />
                <Route path='/bookings' element={accessToken ? <Bookings /> : <Login />} />
                <Route path='/activate-account' element={<ActivateAccount />} />
                <Route path='/activate-booking-account' element={<ActivateBookingAccount />} />
                <Route path='/book-promotion/:id' element={<BookPromotion />} />
              </Routes>
              {/* <Footer /> */}
            </div>
          )}
        </>
      )}
    </div>
  );
}

export default App;
