import React, { useState, useEffect } from 'react';
import styles from './EventDetails.module.css';
import axios from 'axios';
import { API_URL } from '../constant';
import { useParams } from 'react-router-dom';
import Loader from '../Components/Loader';
import "./quill.css";

const EventDetails = () => {
  const { id } = useParams();

  const [loading, setLoading] = useState(false);
  const [eventData, setEventData] = useState({});
  const [descriptionDetails, setDescriptionDetails] = useState("");

  const getEventData = async () => {
    try {
      const response = await axios.get(`${API_URL}/api/getEventById/${id}`);
      setEventData(response.data);

      setDescriptionDetails(response.data.LongDescription.replace(/&nbsp;/g, ' '));
    } catch (error) {
      console.log(error);
    }
  }

  const formatDate = (date) => {
    // return data like Friday December 1, 2024
    const options = { weekday: 'long', day: 'numeric', month: 'long', year: 'numeric' };
    return date.toLocaleDateString('en-US', options);
  }

  useEffect(() => {
    getEventData();
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      {loading ? <Loader /> : (
        <div className={styles.event_page_container4}>
        <div className={`${styles.event_details_container_inner} event-details-container`}>
            <div
                className={`${styles.event_details_description} ql-editor`}
                dangerouslySetInnerHTML={{ __html: descriptionDetails }}
            />
        </div>
    </div>
      )}
    </>
  )
}

export default EventDetails
