import React, { useState } from 'react';
import styles from './Dropdown2.module.css';

const TypoSearch = ({ options, value, onChange }) => {
  const [searchText, setSearchText] = useState(value || ""); // Initialize with parent value
  const [isOpen, setIsOpen] = useState(false);

  const handleInputChange = (e) => {
    setSearchText(e.target.value);
    setIsOpen(true);
  };

  const handleOptionClick = (label) => {
    setSearchText(label);
    setIsOpen(false);
    onChange(label); // Notify parent of the selected value
  };

  const filteredOptions = options.filter((option) =>
    option.label.toLowerCase().includes(searchText.toLowerCase())
  );

  return (
    <div className={styles.typo_search_container}>
      <input
        type="text"
        className={styles.typo_search_input}
        placeholder="Search Country"
        value={searchText}
        onChange={handleInputChange}
        onFocus={() => setIsOpen(true)} // Open dropdown when input is focused
      />
      {isOpen && searchText && (
        <div className={styles.typo_search_results}>
          {filteredOptions.length > 0 ? (
            filteredOptions.map((option, index) => (
              <div
                key={index}
                className={styles.typo_search_result}
                onClick={() => handleOptionClick(option.label)}
              >
                {option.label}
              </div>
            ))
          ) : (
            <div className={styles.typo_search_no_results}>No results found</div>
          )}
        </div>
      )}
    </div>
  );
};

export default TypoSearch;
