import axios from 'axios';
import { API_URL } from './constant';
import Swal from 'sweetalert2';

const refreshAccessToken = async () => {
    try {
        const refreshToken = localStorage.getItem('refreshToken');
        if (!refreshToken) {
            localStorage.removeItem('accessToken');
            localStorage.removeItem('refreshToken');
            localStorage.removeItem('userData');
            Swal.fire({
                icon: "error",
                title: "Error",
                text: "Please login again",
                confirmButtonText: "OK",
            }).then((result) => {
                if (result.isConfirmed) {
                    window.location.reload();
                }
            });
        }

        const response = await axios.post(`${API_URL}/api/refresh-accessToken`, { refreshToken });

        if (response.status === 200) {
            localStorage.setItem('accessToken', response.data.accessToken);
            window.location.reload();
        }
    } catch (error) {
        console.log(error);
        
        if (error.response.status === 403) {
            localStorage.removeItem('accessToken');
            localStorage.removeItem('refreshToken');
            localStorage.removeItem('userData');
            
            //create sweetalert and on click ok, it will reload
            Swal.fire({
                icon: "error",
                title: "Error",
                text: "Please login again",
                confirmButtonText: "OK",
            }).then((result) => {
                if (result.isConfirmed) {
                    window.location.reload();
                }
            });
        }

        if (error.response.status === 500) {
            localStorage.removeItem('accessToken');
            localStorage.removeItem('refreshToken');
            localStorage.removeItem('userData');
            
            Swal.fire({
                icon: "error",
                title: "Error",
                text: "Please login again",
                confirmButtonText: "OK",
            }).then((result) => {
                if (result.isConfirmed) {
                    window.location.reload();
                }
            });
        }
    }
}

export default refreshAccessToken;